const NestedSelectionFilter = (dataIndex, dataSource) => {
    const getFiltersArray = () => {
        const data = dataSource.map(record => record[dataIndex]).flat();
        let filtersNames = [];
        data.forEach(d => {
            if (d) {
                const { name } = d;
                filtersNames = [...filtersNames, name];
            }
        });

        filtersNames = filtersNames.filter((f, index) => filtersNames.indexOf(f) === index);
        const filters = filtersNames.map(f => ({
            text: f,
            value: f,
        }));
        return filters;
    };

    const filterData = (value, record) => {
        let matches = false;
        const data = record[dataIndex];
        if (data) {
            data.forEach(d => {
                if (d?.name.includes(value)) {
                    matches = true;
                }
            });
        }
        return matches;
    };

    return (
        {
            filters: getFiltersArray(),
            onFilter: (value, record) => filterData(value, record),
            filterMode: 'tree',
        }
    );
};

export default NestedSelectionFilter;
