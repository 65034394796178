import { Record } from 'immutable';

const {
    SET_CURRENT_PROJECT,
} = require('./projectSettingsActions').constraints;

const InitialState = Record({
    currentProject: null,
});

const initialState = new InitialState();

export default function projectSettingsReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {

    case SET_CURRENT_PROJECT:
        return state.set('currentProject', payload);

    default:
        return state;
    }
}
