import { Record } from 'immutable';
import moment from 'moment';

import { toHours } from '../utils/time';
import { toPercentage } from '../utils/percentage';
import { getMonth, getQuarter, getYear } from '../utils/date';

const {
    GET_PRODUCTIVITY_ACCURACY_REQUEST,
    GET_PRODUCTIVITY_ACCURACY_SUCCESS,
    GET_PRODUCTIVITY_ACCURACY_FAILURE,
} = require('./projectAccuracyActions').constraints;

const InitialState = Record({
    error: null,
    isFetching: false,
    projectBreakdowns: [],
    projectAccuracyByPeriod: [],
    quarterlyAccuracy: [],
});

const initialState = new InitialState();

export default function projectAccuracyReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_PRODUCTIVITY_ACCURACY_REQUEST:
        return state
            .set('isFetching', true)
            .set('error', null);

    case GET_PRODUCTIVITY_ACCURACY_SUCCESS: {
        if (!payload) return state.set('isFetching', false);

        const { project_accuracy, project_breakdown } = payload;
        let quarterlyAccuracy = [];

        const projectAccuracyByPeriod = project_accuracy.map((accuracy) => {
            const { period,
                combined_accuracy,
                dev_accuracy,
                pm_accuracy,
                testing_accuracy,
            } = accuracy;

            const year = getYear(period);
            const month = getMonth(period);

            return {
                key: `${year}-${month}`,
                year,
                month,
                combinedAccuracy: toPercentage(combined_accuracy),
                devAccuracy: toPercentage(dev_accuracy),
                pmAccuracy: toPercentage(pm_accuracy),
                qaAccuracy: toPercentage(testing_accuracy),
            };
        });

        project_accuracy.forEach((accuracy) => {
            const quarter = getQuarter(accuracy.period);
            const year = getYear(accuracy.period);

            const index = quarterlyAccuracy.findIndex((entry) => entry.quarter === quarter && entry.year === year);

            if (index > -1) {
                quarterlyAccuracy[index].numOfMonths += 1;
                quarterlyAccuracy[index].combinedAccuracy += parseFloat(accuracy.combined_accuracy);
                quarterlyAccuracy[index].devAccuracy += parseFloat(accuracy.dev_accuracy);
                quarterlyAccuracy[index].pmAccuracy += parseFloat(accuracy.pm_accuracy);
                quarterlyAccuracy[index].qaAccuracy += parseFloat(accuracy.testing_accuracy);
            } else {
                const quarterEntry = {
                    quarter,
                    year,
                    numOfMonths: 1,
                    combinedAccuracy: parseFloat(accuracy.combined_accuracy),
                    devAccuracy: parseFloat(accuracy.dev_accuracy),
                    pmAccuracy: parseFloat(accuracy.pm_accuracy),
                    qaAccuracy: parseFloat(accuracy.testing_accuracy),
                };

                quarterlyAccuracy = [...quarterlyAccuracy, quarterEntry];
            }
        });

        quarterlyAccuracy = quarterlyAccuracy.map((quarter) => {
            const entry = { ...quarter };

            entry.combinedAccuracy /= entry.numOfMonths;
            entry.devAccuracy /= entry.numOfMonths;
            entry.pmAccuracy /= entry.numOfMonths;
            entry.qaAccuracy /= entry.numOfMonths;

            entry.combinedAccuracy = toPercentage(entry.combinedAccuracy);
            entry.devAccuracy = toPercentage(entry.devAccuracy);
            entry.pmAccuracy = toPercentage(entry.pmAccuracy);
            entry.qaAccuracy = toPercentage(entry.qaAccuracy);

            return entry;
        });

        const projectBreakdowns = project_breakdown.map((breakdown) => {
            const { id,
                code,
                name,
                period,
                dev_monthly_logged,
                dev_monthly_estimate,
                pm_budget_opening,
                pm_monthly_logged,
                pm_monthly_estimate,
                pm_budget_closing,
                testing_budget_opening,
                testing_monthly_logged,
                testing_monthly_estimate,
                testing_budget_closing,
                fixing_budget_opening,
                fixing_monthly_logged,
                fixing_monthly_estimate,
                fixing_budget_closing,
            } = breakdown;

            return {
                key: id,
                code,
                name,
                period: moment(period).format('DD/MM/YYYY'),
                devMonthlyLogged: toHours(dev_monthly_logged),
                devMonthlyEstimate: toHours(dev_monthly_estimate),
                pmBudgetOpening: toHours(pm_budget_opening),
                pmMonthlyLogged: toHours(pm_monthly_logged),
                pmMonthlyEstimate: toHours(pm_monthly_estimate),
                pmBudgetClosing: toHours(pm_budget_closing),
                testingBudgetOpening: toHours(testing_budget_opening),
                testingMonthlyLogged: toHours(testing_monthly_logged),
                testingMonthlyEstimate: toHours(testing_monthly_estimate),
                testingBudgetClosing: toHours(testing_budget_closing),
                fixingBudgetOpening: toHours(fixing_budget_opening),
                fixingMonthlyLogged: toHours(fixing_monthly_logged),
                fixingMonthlyEstimate: toHours(fixing_monthly_estimate),
                fixingBudgetClosing: toHours(fixing_budget_closing),
            };
        });

        return state
            .set('isFetching', false)
            .set('projectBreakdowns', projectBreakdowns)
            .set('projectAccuracyByPeriod', projectAccuracyByPeriod)
            .set('quarterlyAccuracy', quarterlyAccuracy);
    }

    case GET_PRODUCTIVITY_ACCURACY_FAILURE:
        return state
            .set('isFetching', false)
            .set('error', payload);

    default:
        return state;
    }
}
