import React from 'react';
import PropTypes from 'prop-types';
import { Layout } from 'antd';

import './footer.css';

const Footer = ({ children, floating }) => (
    <Layout.Footer className={`footer ${floating ? 'floating-footer' : ''}`}>
        {children}
    </Layout.Footer>
);

Footer.defaultProps = {
    floating: false,
    children: false,
};

Footer.propTypes = {
    children: PropTypes.node,
    floating: PropTypes.bool,
};

export default Footer;
