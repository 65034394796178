import React from 'react';

import scorchsoftLogo from '../../assets/images/scorchsoft-logo-white.png';
import './style.css';

const PrintBanner = () => (
    <div className="banner">
        <img className="banner-logo" src={scorchsoftLogo} alt="Scorchsoft Logo" />
    </div>
);

export default PrintBanner;
