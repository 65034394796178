function toHours(time) {
    if ([null, undefined, NaN, 0].includes(time)) {
        return 0;
    }

    return (time / 3600).toFixed(2);
}

function sumTimeLogsDuration(timeLogs) {
    return timeLogs.reduce((sum, log) => sum + log.duration, 0);
}

export {
    // eslint-disable-next-line import/prefer-default-export
    toHours,
    sumTimeLogsDuration,
};
