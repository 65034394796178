import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spin, Select, Pagination } from 'antd';

import PropTypes from 'prop-types';

import './billingPeriod.css';
import CoreTable from '../../components/CoreTable/CoreTable';
import { TYPES } from '../../components/CoreTable/config';
import { getBillingPeriodDataRequest } from '../../core/agileProject/agileProjectActions';

const BillingPeriod = ({ view, viewId }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const projectId = location?.pathname.split('/')[2];
    const searchParams = new URLSearchParams(location.search);
    const urlViewId = parseInt(searchParams.get('view_id'), 10);
    const selectProject = useSelector((state) => state?.agileProject);
    const { settings, billingPeriods, selectedBillingPeriod, billingPeriodPagination, isFetching } = useSelector((state) => state?.agileProject);
    const [data, setData] = useState();

    const [viewBillingPeriodId, setViewBillingPeriodId] = useState(parseInt(viewId, 10) || null);
    const [periods, setPeriods] = useState(selectProject?.billingPeriods);
    const initialValue = periods.find((period) => period?.id === parseInt(viewBillingPeriodId, 10))?.period;

    const [selected, setSelected] = useState(initialValue || '');
    const [showTable, setShowTable] = useState(true);
    const dispatch = useDispatch();
    const [pageSize, setPageSize] = useState(10);
    const [page, setPage] = useState(1);

    const [filterInfo, setFilterInfo] = useState(null);
    const [sorterInfo, setSorterInfo] = useState(null);
    const handleFilterChange = (filter) => {
        setFilterInfo(filter);
    };
    const parsedBillingViewId = searchParams.get('view_id');

    const onUpdate = () => {
        if (parsedBillingViewId) {
            const period = parsedBillingViewId.split(',').map((pid) => {
                const periodIds = billingPeriods.find((p) => p?.id === parseInt(pid, 10));
                return periodIds?.start_date;
            });
            if (period) {
                dispatch(getBillingPeriodDataRequest({
                    projectCode: settings.projectCode,
                    projectName: settings.projectName,
                    period,
                    projectId: settings?.id,
                    periodId: parsedBillingViewId.split(','),
                    page,
                    pageSize,
                }));
            }
        }
    };

    useEffect(() => {
        onUpdate();
    }, [page, pageSize, parsedBillingViewId]);

    const handleSorterChange = (sorter) => {
        setSorterInfo(sorter);
    };

    const handleChangePage = (pageNumber) => {
        setPage(pageNumber);
    };
    const handleChangePageSize = (current, size) => {
        setPageSize(size);
        setPage(1);
    };
    const showTotal = (total) => `Results: ${billingPeriodPagination.from} - ${billingPeriodPagination.to} of ${total}`;

    // A1 page size
    const printStyles = `@media print {
        @page {
            size: 841mm 594mm;
            margin: 0mm;
        }
        body {
            background-color: #ffffff !important;
        }
        .ant-table .ant-table-container::before,
        .ant-table .ant-table-container::after {
            width: 0px !important;
        }
        .printable {
            display: block !important;
        }
        .not-printable {
            display: none !important;
        }
        .printable-content {
            padding-top: 10mm;
            padding-right: 20mm;
            padding-left: 20mm;
            padding-bottom: 10mm;
            background-color: #ffffff !important;
        }
    }`;

    useEffect(() => {
        setPeriods(selectProject?.billingPeriods);
        setSelected(initialValue);
    }, [settings, initialValue]);

    useEffect(() => {
        if (urlViewId) {
            setData(selectedBillingPeriod.find(period => period.periodId.includes((urlViewId).toString()))?.data);
        } else {
            setData(selectedBillingPeriod.find(period => period.projectId === projectId)?.data);

        }
    }, [selected, initialValue, viewId, viewBillingPeriodId, selectedBillingPeriod]);

    useEffect(() => {
        setViewBillingPeriodId(selectedBillingPeriod.find(period => period?.projectId === settings?.id)?.periodId?.map(Number));
    }, []);

    const items = periods.map((period) => ({
        label: (
            <div>{period.period}</div>
        ),
        key: period.id,
    }));

    const handleChange = (value) => {
        setViewBillingPeriodId(value);
    };
    const handleBlur = () => {
        if (viewBillingPeriodId) {
            const url = `/report/${settings?.id}?view=${view}&view_id=${viewBillingPeriodId?.join(',')}`;
            navigate(url);
        }
        setSelected(periods.find((period) => period?.id === parseInt(viewBillingPeriodId, 10))?.period);
        setShowTable(true);
    };

    const handleDeSelect = (value) => {
        const updatedPeriod = viewBillingPeriodId.filter((period) => period !== value);
        setViewBillingPeriodId(updatedPeriod);
        if (updatedPeriod.length > 0) {
            const url = `/report/${settings?.id}?view=${view}&view_id=${updatedPeriod?.join(',')}`;
            navigate(url);
        } else {
            const url = `/report/${settings?.id}?view=${view}`;
            navigate(url);
            setSelected(null);
        }
    };

    return (
        <div style={{ margin: 20, marginTop: 30 }}>
            {view === 'billing-period-breakdown'
                && (
                    <>
                        <h2>Select a Billing Period</h2>
                        <Select
                            mode="multiple"
                            allowClear
                            style={{
                                width: '100%',
                            }}
                            placeholder="Select a Billing Period"
                            defaultValue={items.filter(item => selectedBillingPeriod[0]?.periodId?.includes(String(item.key)))}
                            onFocus={() => setShowTable(false)}
                            onDeselect={handleDeSelect}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            options={items.map((item) => ({
                                label: item.label,
                                value: item.key,
                            }))} />
                        <h3 style={{ marginBottom: 20 }}>
                            {selected && (
                                <>
                                    {!selected && <Spin style={{ marginLeft: 10 }} size="small" />}
                                </>
                            )}
                        </h3>
                        { selected && settings && showTable && (
                            <>
                                <CoreTable
                                    type={TYPES.BILLING_PERIOD_BREAKDOWN}
                                    dataSource={data}
                                    isFetching={isFetching}
                                    printable
                                    printStyles={printStyles}
                                    projectName={settings?.projectName}
                                    clientName={settings?.clientName}
                                    selected={selected}
                                    filterInfo={filterInfo}
                                    handleFilterChange={handleFilterChange}
                                    sorterInfo={sorterInfo}
                                    handleSorterChange={handleSorterChange} />
                                <div className="sm-seperator" />
                                <div className="custom-pagination-container">

                                    {billingPeriodPagination.total > 0 && (
                                        <Pagination
                                            showSizeChanger
                                            disabled={isFetching}
                                            pageSizeOptions={['10', '50', '100', '500', '1000']}
                                            current={billingPeriodPagination.current}
                                            total={billingPeriodPagination.total}
                                            pageSize={billingPeriodPagination.page_size}
                                            showTotal={showTotal}
                                            onChange={handleChangePage}
                                            onShowSizeChange={handleChangePageSize} />
                                    )}
                                </div>
                            </>
                        )}
                    </>

                )}
        </div>
    );
};

BillingPeriod.propTypes = {
    onUpdate: PropTypes.func,
    data: PropTypes.object,
    view: PropTypes.string,
    viewId: PropTypes.number,
};

BillingPeriod.defaultProps = {
    onUpdate: null,
    data: null,
    view: null,
    viewId: null,
};

export default BillingPeriod;
