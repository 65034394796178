import { Button, InputNumber, Row, Space } from 'antd';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { FilterFilled, SearchOutlined } from '@ant-design/icons';

/**
 * A Min-Max filter component for Antd tables. Allows the selection of
 * minimum and maximum numeric values.
 * @param {string} dataIndex The dataIndex of the column being filtered.
 * @returns An object to be spread into the column configuration.
 */
const MinMaxFilter = (dataIndex) => {
    const minInputRef = useRef(null);
    const maxInputRef = useRef(null);

    const handleFilter = (confirm) => {
        confirm();
    };

    const handleReset = (clearFilters, confirm) => {
        clearFilters();
        confirm();
    };

    const getMinMaxFilter = () => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8, width: 300 }} onKeyDown={(e) => e.stopPropagation()}>
                {/* Minimum input field */}
                <InputNumber
                    addonBefore="Min"
                    ref={minInputRef}
                    placeholder="0"
                    value={selectedKeys[0]?.min}
                    onChange={(e) => setSelectedKeys([{ ...selectedKeys[0], min: e }])}
                    onPressEnter={() => handleFilter(confirm)}
                    style={{ marginBottom: 8, display: 'block' }} />
                {/* Maximum input field */}
                <InputNumber
                    addonBefore="Max"
                    ref={maxInputRef}
                    placeholder="100"
                    value={selectedKeys[0]?.max}
                    onChange={(e) => setSelectedKeys([{ ...selectedKeys[0], max: e }])}
                    onPressEnter={() => handleFilter(confirm)}
                    style={{ marginBottom: 8, display: 'block' }} />
                <Row>
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => handleFilter(confirm)}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 115 }}>
                            Filter
                        </Button>
                        <Button
                            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
                            size="small"
                            style={{ width: 115 }}>
                            Reset
                        </Button>
                    </Space>
                </Row>
            </div>
        ),
        filterIcon: (filtered) => (
            <FilterFilled style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) => {
            const recordValue = (record[dataIndex]) && record[dataIndex]?.replace('%', '');
            if (value.min == null && value.max == null) {
                return true;
            }
            if (value.min == null) {
                return recordValue <= value.max;
            }
            if (value.max == null) {
                return recordValue >= value.min;
            }
            return recordValue >= value.min && recordValue <= value.max;
        },
        render: (text) => (text),
    });

    return (
        getMinMaxFilter()
    );
};

MinMaxFilter.propTypes = {
    dataIndex: PropTypes.string.isRequired,
};

export default MinMaxFilter;
