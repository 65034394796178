import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Select } from 'antd';

import PropTypes from 'prop-types';

import './sprintTable.css';
import CoreTable from '../../components/CoreTable/CoreTable';
import { TYPES } from '../../components/CoreTable/config';

const SprintTable = ({ onUpdate }) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sprintId = searchParams.get('view_id');
    const projectId = location?.pathname.split('/')[2];
    const { sprints, sprintDataPagination, settings, selectedSprint, isFetching } = useSelector((state) => state.agileProject);
    const initialValue = sprints.find((sprint) => sprint?.id === Number(sprintId))?.name;
    const [filterInfo, setFilterInfo] = useState(null);
    const [sorterInfo, setSorterInfo] = useState(null);
    const handleFilterChange = (filter) => {
        setFilterInfo(filter);
    };

    const handleSorterChange = (sorter) => {
        setSorterInfo(sorter);
    };

    // A2 page size
    const printStyles = `@media print {
        @page {
            size: 594mm 420mm;
            margin: 0mm;
        }
        body {
            background-color: #ffffff !important;
        }
        .ant-table .ant-table-container::before,
        .ant-table .ant-table-container::after {
            width: 0px !important;
        }
        .printable {
            display: block !important;
        }
        .not-printable {
            display: none !important;
        }
        .printable-content {
            padding-top: 10mm;
            padding-right: 20mm;
            padding-left: 20mm;
            padding-bottom: 10mm;
            background-color: #ffffff !important;
        }
    }`;

    const items = useMemo(() => sprints.map((sprint) => ({
        label: (
            <Link key={sprint.id} to={`/report/${projectId}?view=sprint-breakdown&view_id=${sprint.id}`}>
                <div>{sprint.name}</div>
            </Link>
        ),
        key: sprint.id,
        name: sprint.name,
        value: sprint.id,
    })), [sprints]);

    const onSelect = ({ key }) => {
        onUpdate({
            viewId: key,
        });
    };

    useEffect(() => {
        if (sprintId) {
            onUpdate({
                viewId: sprintId,
            });
        }
    }, [sprintId]);

    const filterOption = (input, option) => (option?.name ?? '').toLowerCase().includes(input.toLowerCase());

    return (
        <div style={{ margin: 20, marginTop: 30 }}>

            <h2>Select a Sprint</h2>
            <Select
                style={{
                    width: '100%',
                    marginBottom: 20,
                }}
                labelInValue
                placeholder="Select a Sprint"
                showSearch
                onSelect={onSelect}
                options={items}
                optionFilterProp="children"
                filterOption={filterOption}
                value={initialValue} />
            {sprintId && (
                <>
                    <CoreTable
                        type={TYPES.SPRINT_BREAKDOWN}
                        dataSource={selectedSprint?.data}
                        isFetching={isFetching}
                        printable
                        printStyles={printStyles}
                        projectName={settings?.projectName}
                        clientName={settings?.clientName}
                        selected={initialValue}
                        filterInfo={filterInfo}
                        handleFilterChange={handleFilterChange}
                        sorterInfo={sorterInfo}
                        handleSorterChange={handleSorterChange} />
                    {sprintDataPagination.total > 0 && (
                        <>
                            <div className="sm-seperator" />
                            <div className="custom-pagination-container" />
                        </>
                    )}
                </>
            )}
        </div>
    );
};

SprintTable.propTypes = {
    onUpdate: PropTypes.func,
    data: PropTypes.object,
    view: PropTypes.string,
    viewId: PropTypes.number,
};

SprintTable.defaultProps = {
    onUpdate: null,
    data: null,
    view: null,
    viewId: null,
};

export default SprintTable;
