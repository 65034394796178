export const SimpleSorter = (a, b, dataIndex) => a[dataIndex] - b[dataIndex];

export const CleanSorter = (a, b, dataIndex) => {
    const x = (a[dataIndex] === '-' || undefined) ? 0 : a[dataIndex];
    const y = (b[dataIndex] === '-' || undefined) ? 0 : b[dataIndex];
    return x - y;
};

export const PercentSorter = (a, b, dataIndex) => {
    const x = (a[dataIndex] === '-' || undefined) ? 0 : a[dataIndex].replace('%', '');
    const y = (b[dataIndex] === '-' || undefined) ? 0 : b[dataIndex].replace('%', '');
    return x - y;
};
