import {
    Button,
    Cascader,
    Checkbox,
    Input,
    InputNumber,
    Mentions,
    Radio,
    Rate,
    Select,
    Slider,
    Switch,
    TimePicker,
    Transfer,
    TreeSelect,
    Upload,
    ColorPicker,
} from 'antd';

import DatePicker from '../PickerComponent/DatePicker';

const { RangePicker } = DatePicker;

const components = {
    Button,
    Cascader,
    Checkbox,
    DatePicker,
    Input,
    InputNumber,
    Mentions,
    Radio,
    Rate,
    RangePicker,
    Select,
    Slider,
    Switch,
    TimePicker,
    Transfer,
    TreeSelect,
    Upload,
    ColorPicker,
    'Checkbox.Group': Checkbox.Group,
    'Input.Group': Input.Group,
    'Input.Password': Input.Password,
    'Input.Search': Input.Search,
    'Input.TextArea': Input.TextArea,
    'Radio.Group': Radio.Group,
};

export default components;
