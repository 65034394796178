import { Record } from 'immutable';

import { toHours } from '../utils/time';

const {
    GET_PROJECTS_REQUEST,
    GET_PROJECTS_SUCCESS,
    GET_PROJECTS_FAILURE,

    UPDATE_REPORTED_STATUS_REQUEST,
    UPDATE_REPORTED_STATUS_SUCCESS,
    UPDATE_REPORTED_STATUS_FAILURE,
} = require('./programmeOverviewActions').constants;

const InitialState = Record({
    error: null,
    isFetching: false,
    isFetchingReportedStatus: false,
    projects: [],
});

const initialState = new InitialState();

const calculateDelta = (sumActualHrs, sumQuotedHrs) => {
    if (sumQuotedHrs === 0) return 0;

    const delta = sumActualHrs - sumQuotedHrs;
    return (delta / sumQuotedHrs).toFixed(2);

};

/**
 * Programme overview reducer
 *
 * @param {Object} state - initialState
 * @param {Object} action - type and payload
 */
export default function programmeOverviewReducer(state = initialState, { payload, type }) {
    if (!(state instanceof InitialState)) return initialState.mergeDeep(state);

    switch (type) {
    case GET_PROJECTS_REQUEST:
        return state.set('isFetching', true)
            .set('error', null);

    case GET_PROJECTS_SUCCESS: {
        if (!payload) return state.set('isFetching', false);

        const projects = payload.map((project) => {
            const { id,
                code,
                name,
                overview,
                reported_in_overview,
                start_at,
                feature_complete_at,
                project_finished_at,
            } = project;

            const hourlyRate = overview.budgets?.projectRate;
            const sumQuotedHrs = overview.budgets?.planningBudget + overview.budgets?.graphicsBudget + overview.budgets?.devBudget + overview.budgets?.pmBudget + overview.budgets?.pmTestingBudget + overview.budgets?.devFixingBudget;
            const sumActualHrs = toHours(overview.summary?.planningTime + overview.summary?.graphicsTime + overview.summary?.developmentTime + overview.summary?.projectTime + overview.summary?.testingTime + overview.summary?.bugsTime);
            const sumQuotedCost = (sumQuotedHrs * hourlyRate);
            const sumActualCost = (sumActualHrs * hourlyRate);
            const pcDeltaHours = calculateDelta(sumActualHrs, sumQuotedHrs);

            return {
                key: id,
                reported: !!reported_in_overview,
                code,
                name,
                hourlyRate,
                startDate: start_at,
                plannedCompletionDate: feature_complete_at,
                actualCompletionDate: project_finished_at,
                planningBudget: overview.budgets?.planningBudget,
                actualPlanningTime: toHours(overview.summary?.planningTime),
                quotedGraphicsTime: overview.budgets?.graphicsBudget,
                actualGraphicsTime: toHours(overview.summary?.graphicsTime),
                productiveGraphicsTime: toHours(overview.productivity?.graphicsTime),
                quotedDevTime: overview.budgets?.devBudget,
                actualDevTime: toHours(overview.summary?.developmentTime),
                productiveDevTime: toHours(overview.productivity?.developmentTime),
                quotedPMTime: overview.budgets?.pmBudget,
                actualPMTime: toHours(overview.summary?.projectTime),
                productivePMTime: toHours(overview.productivity?.pmTime),
                pmTestingBudget: overview.budgets?.pmTestingBudget,
                actualPMTesting: toHours(overview.summary?.testingTime),
                productivePMTesting: toHours(overview.productivity?.testingTime),
                devFixingBudget: overview.budgets?.devFixingBudget,
                actualDevFixing: toHours(overview.summary?.bugsTime),
                productiveDevFixing: toHours(overview.productivity?.bugsTime),
                sumQuotedHrs,
                sumActualHrs,
                sumQuotedCost,
                sumActualCost,
                pcDeltaHours,
            };
        });

        return state
            .set('isFetching', false)
            .set('projects', projects);
    }

    case GET_PROJECTS_FAILURE:
        return state
            .set('isFetching', false)
            .set('error', payload);

    case UPDATE_REPORTED_STATUS_REQUEST:
        return state
            .set('isFetchingReportedStatus', true)
            .set('error', null);

    case UPDATE_REPORTED_STATUS_SUCCESS: {
        if (!payload) return state.set('isFetchingReportedStatus', false);

        const { id, reported } = payload;
        const projects = state.projects.map((project) => {
            if (project.key === id) {
                return {
                    ...project,
                    reported,
                };
            }

            return project;
        });

        return state
            .set('isFetchingReportedStatus', false)
            .set('projects', projects);
    }

    case UPDATE_REPORTED_STATUS_FAILURE:
        return state
            .set('isFetchingReportedStatus', false)
            .set('error', payload);

    default:
        return state;
    }
}
