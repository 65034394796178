import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'underscore';
import {
    ColorPicker,
    Form,
    Mentions,
} from 'antd';

import DataDisplayItem from '../../DataDisplay/DataDisplayItem';
import components from '../inputComponents';

import './style.scss';

const getValuePropName = (type) => {
    if (type === 'Switch' || type === 'Checkbox') {
        return 'checked';
    }
    if (type === 'Upload') {
        return 'fileList';
    }
    return 'value';
};

const FormItem = ({ type, children, config, form, ...formItemConfig }) => {
    const { hidden, initialValues, items, ...itemConfig } = formItemConfig;
    const { dependencies } = config;
    const valuePropName = getValuePropName(type);

    const shouldUpdate = (prev, cur) => {
        if (!isEmpty(dependencies)) {
            return !dependencies.every((dep) => prev[dep] === cur[dep]);
        }
        return false;
    };

    const conditionsAreTrue = (conditions) => conditions.every(condition => {
        const { when } = condition;
        // check for the condtional field in the intialValue or allFields depending on form state
        const value = form.isFieldsTouched(true) ? initialValues[when] : form.getFieldValue(when);

        return condition.is === value;
    });

    // const shouldDisableInput = (disabled) => {
    //     const { conditions } = disabled;

    //     if (conditions && conditionsAreTrue(conditions)) {
    //         return true;
    //     }

    //     return false;
    // };

    const renderItem = () => {
        const { Option } = Mentions;
        const InputComponent = components[type];
        const { disabled, ...inputConfig } = config;
        inputConfig.disabled = disabled;

        switch (type) {
        case 'Button':
        case 'Checkbox':
        case 'Radio':
            return <InputComponent {...inputConfig}>{inputConfig.label}</InputComponent>;
        case 'Mentions': {
            const { options, ...mentionsConfig } = inputConfig;

            return (
                <InputComponent {...mentionsConfig}>
                    {options.map((option) => (
                        <Option key={option.value} value={option.value}>{option.label}</Option>
                    ))}
                </InputComponent>
            );
        }
        case 'Input.Group': {
            return <p>Input.Group is not implemented</p>;
        }

        case 'ColorPicker': {
            return <ColorPicker {...inputConfig} showText />;
        }
        default: {
            const hasInvalidValue = inputConfig?.options?.some(option => option.value == null);

            if (hasInvalidValue) {
                return null;
            }

            return <InputComponent {...inputConfig} />;
        }
        }
    };

    itemConfig.className = `ant-form-item-${itemConfig.name} ${itemConfig.className}`;
    itemConfig.initialValue = initialValues[itemConfig.name];
    const hideFormItem = () => hidden?.conditions && conditionsAreTrue(hidden.conditions);

    return (
        <div className="form-item">
            {type === 'DataDisplay'
                ? (
                    <DataDisplayItem label={itemConfig.label} content={itemConfig.content} />
                )
                : (
                    <Form.Item shouldUpdate={(prev, cur) => shouldUpdate(prev, cur)} noStyle>
                        {() => (
                            <Form.Item valuePropName={valuePropName} {...itemConfig} hidden={hideFormItem()}>
                                {renderItem()}
                            </Form.Item>
                        )}
                    </Form.Item>
                )}
        </div>
    );
};

FormItem.propTypes = {
    type: PropTypes.string.isRequired,
    children: PropTypes.node,
    config: PropTypes.object,
    form: PropTypes.object,
};

FormItem.defaultProps = {
    children: null,
    config: {},
    form: {},
};

export default React.memo(FormItem);
