import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchApiAuth, formatParams, makeParamString } from '../utils/api';

import {
    getProjectsSuccess,
    getProjectsFailure,

    updateReportedStatusSuccess,
    updateReportedStatusFailure,
} from './programmeOverviewActions';

const {
    GET_PROJECTS_REQUEST,

    UPDATE_REPORTED_STATUS_REQUEST,
} = require('./programmeOverviewActions').constants;

function* getProjects({ payload }) {
    try {
        const params = makeParamString(formatParams(payload));

        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/projects${params}`,
        });

        yield put(getProjectsSuccess(response.data?.projects));
    } catch (e) {
        yield put(getProjectsFailure(e.response ? e.response.data.message : e));
    }
}

function* updateReportedStatus({ payload }) {
    try {
        const { id, reported } = payload;

        yield call(fetchApiAuth, {
            method: 'PUT',
            url: `/project/${id}/updateReportedStatus`,
            body: {
                reported_in_overview: reported ? 1 : 0,
            },
        });

        yield put(updateReportedStatusSuccess({ id, reported }));
    } catch (e) {
        yield put(updateReportedStatusFailure(e.response ? e.response.data.message : e));
    }
}

export default function* programmeOverviewSagas() {
    yield* [
        takeEvery(GET_PROJECTS_REQUEST, getProjects),
        takeEvery(UPDATE_REPORTED_STATUS_REQUEST, updateReportedStatus),
    ];
}
