import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import './breadcrumbnav.css';

// Example of navigation array
// const navigation = [
//     {
//         key: 0, // optional
//         label: 'Label',
//         link: '', // optional
//         className: '', // optional
//         overlay: null, // optional
//         icon: '', // optional
//     }
// ];

const BreadcrumbNav = ({ navigation, separator }) => (
    <Breadcrumb separator={separator} className="breadcrumb">
        {navigation.map((nav, navIndex) => (
            <Breadcrumb.Item key={nav.key || navIndex} overlay={nav.overlay || null}>
                {nav.link
                    ? <Link className={nav.className} to={nav.link}>{nav.label}</Link>
                    : <span className={nav.className}>{nav.label}</span>}
            </Breadcrumb.Item>
        ))}
    </Breadcrumb>
);

BreadcrumbNav.defaultProps = {
    navigation: [],
    separator: '/',
};

BreadcrumbNav.propTypes = {
    navigation: PropTypes.array,
    separator: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default BreadcrumbNav;
