import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isArray, isEmpty } from 'underscore';
import { Row, Col, Typography } from 'antd';

import DataDisplayRows from '../DataDisplayRows';

import './style.scss';

const { Text } = Typography;

const DataDisplayItem = ({ label, content, children }) => {
    const [isTruncated, setIsTruncated] = useState(true);
    const TRUNCATE_LIMIT = 80;
    const words = content?.split(' ');

    const resultString = (words?.length > TRUNCATE_LIMIT && isTruncated) ? `${words.splice(0, TRUNCATE_LIMIT).join(' ')}...` : content;

    return (
        <div className="data-display-item">
            <Row className="container mb-2">
                <Col className="label mb-2"><Text strong>{label}</Text></Col>
                {isArray(content) && !isEmpty(content)
                    ? <DataDisplayRows content={content} />
                    : (
                        <Col className="content">
                            <Text>
                                { resultString }
                                {words.length > TRUNCATE_LIMIT ? (
                                    <button type="button" style={{ background: 'none', border: 'none', color: 'blue', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => setIsTruncated(!isTruncated)}>
                                        {isTruncated ? ' Show more' : ' Show less'}
                                    </button>
                                ) : null}
                            </Text>
                        </Col>
                    )}
            </Row>
            {children}
        </div>
    );
};

DataDisplayItem.propTypes = {
    label: PropTypes.node,
    content: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.array,
    ]),
    children: PropTypes.any,
};

DataDisplayItem.defaultProps = {
    label: null,
    content: null,
    children: null,
};

export default DataDisplayItem;
