import { call, put, takeEvery } from 'redux-saga/effects';
import { fetchApiAuth, formatParams, makeParamString } from '../utils/api';
import {
    getProductivityAccuracySuccess,
    getProductivityAccuracyFailure,
} from './projectAccuracyActions';

const {
    GET_PRODUCTIVITY_ACCURACY_REQUEST,
} = require('./projectAccuracyActions').constraints;

function* getProductivityAccuracy({ payload }) {
    try {
        const params = makeParamString(formatParams(payload));

        const response = yield call(fetchApiAuth, {
            method: 'GET',
            url: `/projectAccuracy${params}`,
        });

        yield put(getProductivityAccuracySuccess(response.data));
    } catch (e) {
        yield put(getProductivityAccuracyFailure(e.response ? e.response.data.message : e));
    }
}

export default function* projectAccuracySagas() {
    yield* [
        takeEvery(GET_PRODUCTIVITY_ACCURACY_REQUEST, getProductivityAccuracy),
    ];
}
