import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Input } from 'antd';
import PropTypes from 'prop-types';
import { SearchIcon } from '../Elements/CustomSVGIcon';

const CustomFilterDropdown = ({
    setSelectedKeys,
    confirm,
    filters,
    placeholder = 'Search in filters',
    maxHeight = 200,
    selectedKeys,
}) => {
    const [searchText, setSearchText] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const allSelected = filters.length > 0 && selectedOptions.length === filters.length;
    console.log(selectedKeys);
    useEffect(() => {
        setSelectedOptions(selectedKeys);
    }, [selectedKeys]);

    const handleSearch = e => {
        setSearchText(e.target.value);
    };
    const handleSelectAll = () => {
        if (allSelected) {
            setSelectedOptions([]);
        } else {
            setSelectedOptions(filters.map(filter => filter.value));
        }
    };
    const handleCheckboxChange = (value) => {
        setSelectedOptions(prev => (prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]));
    };
    // Render filtered options
    const handleFilterSelection = () => {
        setSelectedKeys(selectedOptions);
        confirm();
    };

    const resetFilters = () => {
        setSelectedOptions([]);
        setSelectedKeys([]);
    };
    // Handle selection and trigger filtering
    const filteredOptions = filters
        .filter(({ text }) => text.toLowerCase().includes(searchText.toLowerCase()))
        .map(filter => (
            <div key={filter.value} style={{ marginBottom: '8px' }}>
                <Checkbox
                    checked={selectedOptions.includes(filter.value)}
                    onChange={() => handleCheckboxChange(filter.value)}>
                    {filter.text}
                </Checkbox>
            </div>
        ));

    return (
        <div style={{ minWidth: '100px' }}>
            <div style={{ padding: '8px', borderBottom: '1px solid #f0f0f0' }} className="ant-table-filter-dropdown-search">
                <span className="ant-input-affix-wrapper css-dev-only-do-not-override-1k979oh ant-input-outlined ant-table-filter-dropdown-search-input">
                    <span className="ant-input-prefix">
                        <span role="img" aria-label="search" className="anticon anticon-search">
                            <SearchIcon />
                        </span>
                    </span>
                    <Input
                        autoFocus
                        placeholder={placeholder}
                        value={searchText}
                        onChange={handleSearch} />
                </span>
            </div>

            {/* Select All Checkbox */}
            <div style={{ padding: '8px' }}>
                <Checkbox
                    checked={allSelected}
                    onChange={handleSelectAll}>
                    Select all items
                </Checkbox>
            </div>

            <div style={{ padding: '0px 8px', maxHeight, overflowY: 'auto', marginLeft: '8px' }} className="ant-table-filter-dropdown-tree">
                {filteredOptions}
            </div>

            <div style={{ padding: '8px', borderTop: '1px solid #f0f0f0', justifyContent: 'space-between' }} className="ant-table-filter-dropdown-btns">
                <Button type="default" size="small" className=" ant-btn-link" onClick={resetFilters} style={{ marginRight: '8px' }}>
                    Reset
                </Button>
                <Button type="primary" size="small" onClick={handleFilterSelection}>
                    OK
                </Button>
            </div>

        </div>
    );
};

export default CustomFilterDropdown;

CustomFilterDropdown.propTypes = {
    setSelectedKeys: PropTypes.func.isRequired,
    confirm: PropTypes.func.isRequired,
    filters: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
    })).isRequired,
    placeholder: PropTypes.string,
    maxHeight: PropTypes.number,
    selectedKeys: PropTypes.array,
};

CustomFilterDropdown.defaultProps = {
    placeholder: 'Search in filters',
    maxHeight: 200,
    selectedKeys: [],
};
