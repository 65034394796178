import keyMirror from 'key-mirror';
import { createActions } from 'redux-actions';

export const constraints = keyMirror({
    SET_CURRENT_PROJECT: null,
});

export const {
    setCurrentProject,
} = createActions(
    constraints.SET_CURRENT_PROJECT,
);
