import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';

import DataDisplayItem from '../DataDisplayItem';

import './style.scss';

const { Text, Title } = Typography;

const DataDisplayGroup = ({ title, subtitle, items, children }) => (
    <div className="data-display-group p-12 mb-16 shadow-lg">
        <div className="mb-8">
            <Row justify="center">
                <Title level={4}>{title}</Title>
            </Row>
            <Row justify="center">
                <Text>{subtitle}</Text>
            </Row>
        </div>
        <Row gutter={[0, 15]}>
            {items.map((item) => (
                <Col span={24}>
                    <DataDisplayItem key={item.id} label={item.label} content={item.content} />
                </Col>
            ))}
        </Row>
        {children}
    </div>
);

DataDisplayGroup.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    items: PropTypes.array,
    children: PropTypes.node,
};

DataDisplayGroup.defaultProps = {
    title: '',
    subtitle: '',
    items: [],
    children: null,
};

export default DataDisplayGroup;
