import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import Main from '../../components/layout/Main';
import EditableTable from '../../components/EditableTable';
import { getProductivityAccuracyRequest } from '../../core/projectAccuracy/projectAccuracyActions';
import './mqProductivityBreakdown.css';
import SearchFilter from '../../components/EditableTable/filters/SearchFilter';
import SelectionFilter from '../../components/EditableTable/filters/SelectionFilter';

const COL_WIDTH_DATE = 120;
const COL_WIDTH_TIME = 120;
const COL_WIDTH_CODE = 100;
const COL_WIDTH_VERSION = 250;

const MQProductivityBreakdown = () => {
    const dispatch = useDispatch();
    const [dataSource, setDataSource] = useState([]);
    const { isFetching, projectBreakdowns } = useSelector(state => state.projectAccuracy);

    const columns = [
        {
            title: 'Project Code',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            fixed: 'left',
            filterSearch: (input, record) => record.code.startsWith(input),
            ...SelectionFilter('code', dataSource),
            width: COL_WIDTH_CODE,
        },
        {
            title: 'Version',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            width: COL_WIDTH_VERSION,
            ...SearchFilter('name', 'Version'),
        },
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
            fixed: 'left',
            ...SelectionFilter('period', dataSource),
            width: COL_WIDTH_DATE,
        },
        {
            title: 'Dev Time',
            dataIndex: 'devMonthlyLogged',
            key: 'devMonthlyLogged',
            fixed: 'left',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.devMonthlyLogged - b.devMonthlyLogged,
        },
        {
            title: 'Dev Estimate',
            dataIndex: 'devMonthlyEstimate',
            key: 'devMonthlyEstimate',
            fixed: 'left',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.devMonthlyEstimate - b.devMonthlyEstimate,
        },
        {
            title: 'PM Budget Opening',
            dataIndex: 'pmBudgetOpening',
            key: 'devMonthlyOpening',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.devMonthlyOpening - b.devMonthlyOpening,
        },
        {
            title: 'PM Time',
            dataIndex: 'pmMonthlyLogged',
            key: 'pmMonthlyLogged',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.pmMonthlyLogged - b.pmMonthlyLogged,
        },
        {
            title: 'PM Estimate',
            dataIndex: 'pmMonthlyEstimate',
            key: 'pmMonthlyEstimate',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.pmMonthlyEstimate - b.pmMonthlyEstimate,
        },
        {
            title: 'PM Budget Closing',
            dataIndex: 'pmBudgetClosing',
            key: 'pmBudgetClosing',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.pmBudgetClosing - b.pmBudgetClosing,
        },
        {
            title: 'Testing Budget Opening',
            dataIndex: 'testingBudgetOpening',
            key: 'testingBudgetOpening',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.testingBudgetOpening - b.testingBudgetOpening,
        },
        {
            title: 'Testing Time',
            dataIndex: 'testingMonthlyLogged',
            key: 'testingMonthlyLogged',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.testingMonthlyLogged - b.testingMonthlyLogged,
        },
        {
            title: 'Testing Estimate',
            dataIndex: 'testingMonthlyEstimate',
            key: 'testingMonthlyEstimate',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.testingMonthlyEstimate - b.testingMonthlyEstimate,
        },
        {
            title: 'Testing Budget Closing',
            dataIndex: 'testingBudgetClosing',
            key: 'testingBudgetClosing',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.testingBudgetClosing - b.testingBudgetClosing,
        },
        {
            title: 'Fixing Budget Opening',
            dataIndex: 'fixingBudgetOpening',
            key: 'fixingBudgetOpening',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.fixingBudgetOpening - b.fixingBudgetOpening,
        },
        {
            title: 'Fixing Time',
            dataIndex: 'fixingMonthlyLogged',
            key: 'fixingMonthlyLogged',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.fixingMonthlyLogged - b.fixingMonthlyLogged,
        },
        {
            title: 'Fixing Estimate',
            dataIndex: 'fixingMonthlyEstimate',
            key: 'fixingMonthlyEstimate',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.fixingMonthlyEstimate - b.fixingMonthlyEstimate,
        },
        {
            title: 'Fixing Budget Closing',
            dataIndex: 'fixingBudgetClosing',
            key: 'fixingBudgetClosing',
            width: COL_WIDTH_TIME,
            sorter: (a, b) => a.fixingBudgetClosing - b.fixingBudgetClosing,
        },
    ];

    useEffect(() => {
        dispatch(getProductivityAccuracyRequest({ type: 10 }));
    }, []);

    useEffect(() => {
        if (!isEmpty(projectBreakdowns)) {
            setDataSource(projectBreakdowns);
        }
    }, [projectBreakdowns]);

    const renderAccuracyTable = () => (
        <div>
            <EditableTable
                columns={columns}
                dataSource={dataSource}
                setDataSource={(data) => console.log(data)}
                loading={isFetching} />
        </div>
    );

    return (
        <Main
            title="M&Q Productivity Breakdown"
            floatingHeader={false}>
            <div>
                <h1 className="title-text title-centre title-top">M&Q Productivity Breakdown</h1>
            </div>
            {renderAccuracyTable()}
        </Main>
    );
};

export default MQProductivityBreakdown;
