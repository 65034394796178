import React, { useEffect, useState } from 'react';
import { Switch } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import Main from '../../components/layout/Main';
import EditableTable from '../../components/EditableTable';
import { getProjectsRequest, updateReportedStatusRequest } from '../../core/programmeOverview/programmeOverviewActions';
import './programmeOverview.css';

const COL_WIDTH_DATE = 180;
const COL_WIDTH_TIME = 100;

const ProgrammeOverview = () => {
    const dispatch = useDispatch();
    const { projects, isFetching, isFetchingReportedStatus } = useSelector(state => state.programmeOverview);
    const [dataSource, setDataSource] = useState([]);

    const handleUpdateReportedStatus = (id, reported) => {
        dispatch(updateReportedStatusRequest({ id, reported }));
    };

    const columns = [
        {
            title: 'Project Code',
            dataIndex: 'code',
            key: 'code',
            align: 'center',
            fixed: 'left',
        },
        {
            title: 'Project Name',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
        },
        {
            title: 'Report',
            dataIndex: 'report',
            key: 'report',
            render: (_, record) => (dataSource.length >= 1 ? (
                <Switch
                    loading={isFetchingReportedStatus}
                    size="small"
                    defaultChecked={record.reported}
                    onChange={() => handleUpdateReportedStatus(record.key, !record.reported)} />
            ) : null),
            align: 'center',
            filters: [
                {
                    text: 'Reported',
                    value: true,
                },
                {
                    text: 'Not Reported',
                    value: false,
                },
            ],
            onFilter: (filterValue, { reported }) => reported === filterValue,
        },
        {
            title: 'Actual Start Date',
            dataIndex: 'startDate',
            key: 'startDate',
            editable: true,
            width: COL_WIDTH_DATE,
        },
        {
            title: 'Planned Completion Date',
            dataIndex: 'plannedCompletionDate',
            key: 'plannedCompletionDate',
            editable: true,
            width: COL_WIDTH_DATE,
        },
        {
            title: 'Actual Completion Date',
            dataIndex: 'actualCompletionDate',
            key: 'actualCompletionDate',
            editable: true,
            width: COL_WIDTH_DATE,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            editable: true,
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Hourly rate',
            key: 'hourlyRate',
            dataIndex: 'hourlyRate',
            format: 'currency',
        },
        {
            title: 'Planning budget',
            key: 'planningBudget',
            dataIndex: 'planningBudget',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual planning time',
            key: 'actualPlanningTime',
            dataIndex: 'actualPlanningTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Quoted graphics time',
            key: 'quotedGraphicsTime',
            dataIndex: 'quotedGraphicsTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual graphics time',
            key: 'actualGraphicsTime',
            dataIndex: 'actualGraphicsTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive Graphics Time',
            key: 'productiveGraphicsTime',
            dataIndex: 'productiveGraphicsTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Quoted dev time',
            key: 'quotedDevTime',
            dataIndex: 'quotedDevTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual Dev time',
            key: 'actualDevTime',
            dataIndex: 'actualDevTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive Dev Time',
            key: 'productiveDevTime',
            dataIndex: 'productiveDevTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Quoted PM time',
            key: 'quotedPMTime',
            dataIndex: 'quotedPMTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual PM time',
            key: 'actualPMTime',
            dataIndex: 'actualPMTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive PM Time',
            key: 'productivePMTime',
            dataIndex: 'productivePMTime',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'PM Testing Budget',
            key: 'pmTestingBudget',
            dataIndex: 'pmTestingBudget',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual PM Testing',
            key: 'actualPMTesting',
            dataIndex: 'actualPMTesting',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive PM Testing',
            key: 'productivePMTesting',
            dataIndex: 'productivePMTesting',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Dev Fixing Budget',
            key: 'devFixingBudget',
            dataIndex: 'devFixingBudget',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Actual Dev Fixing',
            key: 'actualDevFixing',
            dataIndex: 'actualDevFixing',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Productive Dev Fixing',
            key: 'productiveDevFixing',
            dataIndex: 'productiveDevFixing',
            width: COL_WIDTH_TIME,
        },
        {
            title: 'Σ Quoted hrs',
            key: 'sumQuotedHrs',
            dataIndex: 'sumQuotedHrs',
        },
        {
            title: 'Σ Actual hrs',
            key: 'sumActualHrs',
            dataIndex: 'sumActualHrs',
        },
        {
            title: 'Σ Quoted cost',
            key: 'sumQuotedCost',
            dataIndex: 'sumQuotedCost',
            format: 'currency',
        },
        {
            title: 'Σ Actual cost',
            key: 'sumActualCost',
            dataIndex: 'sumActualCost',
            format: 'currency',
        },
        {
            title: '%Δ hours',
            key: 'pcDeltaHours',
            dataIndex: 'pcDeltaHours',
            format: 'percent',
        },
    ];

    useEffect(() => {
        dispatch(getProjectsRequest({ type: 10, overview: true }));
    }, []);

    useEffect(() => {
        if (!isEmpty(projects)) {
            setDataSource(projects);
        }
    }, [projects]);

    const renderOverviewTable = () => (
        <div>
            <EditableTable
                columns={columns}
                dataSource={dataSource}
                setDataSource={(data) => { console.log(data); }}
                loading={isFetching} />
        </div>
    );

    return (
        <Main
            title=""
            floatingHeader={false}>
            <div>
                <h1 className="title-text title-centre title-top">Programme Overview</h1>
            </div>
            {renderOverviewTable()}
        </Main>
    );

};

export default ProgrammeOverview;
