import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constraints = keyMirror({
    GET_PRODUCTIVITY_ACCURACY_REQUEST: null,
    GET_PRODUCTIVITY_ACCURACY_SUCCESS: null,
    GET_PRODUCTIVITY_ACCURACY_FAILURE: null,
});

export const {
    getProductivityAccuracyRequest,
    getProductivityAccuracySuccess,
    getProductivityAccuracyFailure,
} = createActions(
    constraints.GET_PRODUCTIVITY_ACCURACY_REQUEST,
    constraints.GET_PRODUCTIVITY_ACCURACY_SUCCESS,
    constraints.GET_PRODUCTIVITY_ACCURACY_FAILURE,
);
