import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Typography } from 'antd';

import './style.scss';

const { Text } = Typography;

const DataDisplayRows = ({ content, children }) => (
    <div className="data-display-rows mt-4">
        {
            content.map((item) => (
                <Row key={item.id} gutter={[16, 8]}>
                    <Col xs={24} sm={12} md={6}><Text strong>{item.label}</Text></Col>
                    <Col xs={24} sm={12} md={18}><Text>{item.content}</Text></Col>
                </Row>
            ))
        }
        {children}
    </div>
);

DataDisplayRows.propTypes = {
    content: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.node,
            content: PropTypes.node,
        }),
    ),
    children: PropTypes.node,
};

DataDisplayRows.defaultProps = {
    content: [],
    children: null,
};

export default DataDisplayRows;
