function toString(time) {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(time)) {
        return 0;
    }
    return time !== 0 ? time.toFixed(2) : time;
}

// eslint-disable-next-line import/prefer-default-export
export { toString };
