import moment from 'moment';

function getYear(date) {
    return moment(date, 'YYYY-MM-DD').format('YYYY');
}

function getMonth(date) {
    return moment(date, 'YYYY-MM-DD').format('MMMM');
}

function getQuarter(date) {
    return moment(date).quarter();
}

export { getYear, getMonth, getQuarter };
