import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'underscore';
import Main from '../../components/layout/Main';
import EditableTable from '../../components/EditableTable';
import { getProductivityAccuracyRequest } from '../../core/projectAccuracy/projectAccuracyActions';
import './mqAccuracyReview.css';

const COL_WIDTH_MONTH = 60;
const COL_WIDTH_YEAR = 50;
const COL_WIDTH_PERECNTAGE = 60;
const COL_WIDTH_QUARTER = 50;

const MQAccuracyReview = () => {
    const dispatch = useDispatch();
    const [monthlyData, setMonthlyData] = useState([]);
    const [quarterlyData, setQuarterlyData] = useState([]);
    const { isFetching, projectAccuracyByPeriod, quarterlyAccuracy } = useSelector(state => state.projectAccuracy);

    const monthlyColumns = [
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            width: COL_WIDTH_YEAR,
        },
        {
            title: 'Month',
            dataIndex: 'month',
            key: 'month',
            width: COL_WIDTH_MONTH,
        },
        {
            title: 'Project Accuracy',
            dataIndex: 'combinedAccuracy',
            key: 'combinedAccuracy',
            width: COL_WIDTH_PERECNTAGE,
            format: 'percentWithoutMultiply',
        },
        {
            title: 'Dev Accuracy',
            dataIndex: 'devAccuracy',
            key: 'devAccuracy',
            width: COL_WIDTH_PERECNTAGE,
            format: 'percentWithoutMultiply',
        },
        {
            title: 'PM Accuracy',
            dataIndex: 'pmAccuracy',
            key: 'pmAccuracy',
            width: COL_WIDTH_PERECNTAGE,
            format: 'percentWithoutMultiply',
        },
        {
            title: 'QA Accuracy',
            dataIndex: 'qaAccuracy',
            key: 'qaAccuracy',
            width: COL_WIDTH_PERECNTAGE,
            format: 'percentWithoutMultiply',
        },
    ];
    const quarterlyColumns = [
        {
            title: 'Year',
            dataIndex: 'year',
            key: 'year',
            width: COL_WIDTH_YEAR,
        },
        {
            title: 'Quarter',
            dataIndex: 'quarter',
            key: 'quarter',
            width: COL_WIDTH_QUARTER,
            format: 'quarter',
        },
        {
            title: 'Project Accuracy',
            dataIndex: 'combinedAccuracy',
            key: 'combinedAccuracy',
            width: COL_WIDTH_PERECNTAGE,
            format: 'percentWithoutMultiply',
        },
        {
            title: 'Dev Accuracy',
            dataIndex: 'devAccuracy',
            key: 'devAccuracy',
            width: COL_WIDTH_PERECNTAGE,
            format: 'percentWithoutMultiply',
        },
        {
            title: 'PM Accuracy',
            dataIndex: 'pmAccuracy',
            key: 'pmAccuracy',
            width: COL_WIDTH_PERECNTAGE,
            format: 'percentWithoutMultiply',
        },
        {
            title: 'QA Accuracy',
            dataIndex: 'qaAccuracy',
            key: 'qaAccuracy',
            width: COL_WIDTH_PERECNTAGE,
            format: 'percentWithoutMultiply',
        },
    ];

    useEffect(() => {
        dispatch(getProductivityAccuracyRequest({ type: 10 }));
    }, []);

    useEffect(() => {
        if (!isEmpty(projectAccuracyByPeriod)) {
            setMonthlyData(projectAccuracyByPeriod);
        }
    }, [projectAccuracyByPeriod]);

    useEffect(() => {
        if (!isEmpty(quarterlyAccuracy)) {
            setQuarterlyData(quarterlyAccuracy);
        }
    }, [quarterlyAccuracy]);

    const renderMonthlyTable = () => (
        <div style={{ flex: 1, width: '45%' }}>
            <EditableTable
                columns={monthlyColumns}
                dataSource={monthlyData}
                setDataSource={(data) => console.log(data)}
                loading={isFetching} />
        </div>
    );

    const renderQuarterlyTable = () => (
        <div style={{ flex: 1, width: '45%' }}>
            <EditableTable
                columns={quarterlyColumns}
                dataSource={quarterlyData}
                setDataSource={(data) => console.log(data)}
                loading={isFetching} />

        </div>
    );

    return (
        <Main
            title="M&Q Accuracy Review"
            floatingHeader={false}>
            <div>
                <h1 className="title-text title-centre title-top">Monthly & Quarterly Productivity Scores</h1>
            </div>
            <div style={{ display: 'flex', gap: '10px' }}>
                {renderMonthlyTable()}
                {renderQuarterlyTable()}
            </div>
        </Main>
    );
};

export default MQAccuracyReview;
