import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import FormSection from './FormSection';

import './style.scss';

const FormDisplay = ({ form, config, formConfig, children }) => {
    const { initialValues, onFieldsChange, layout, ...tempFormConfig } = formConfig;

    const renderSection = (section) => {
        const sectionConfig = section;
        sectionConfig.initialValues = initialValues;

        return <FormSection key={section.id} form={form} {...sectionConfig} />;
    };

    return (
        <div className="form-display">
            <Form form={form} onFieldsChange={onFieldsChange} {...tempFormConfig} layout={layout}>
                {config.sections.map((item) => (
                    renderSection(item)
                ))}
                {children}
            </Form>
        </div>
    );
};

FormDisplay.propTypes = {
    form: PropTypes.object,
    config: PropTypes.object.isRequired,
    formConfig: PropTypes.shape({
        initialValues: PropTypes.object,
        onFieldsChange: PropTypes.func,
        layout: PropTypes.string,
    }),
    children: PropTypes.node,
};

FormDisplay.defaultProps = {
    form: {},
    formConfig: {
        initialValues: {},
        onFieldsChange: null,
        layout: 'vertical',
    },
    children: null,
};

export default React.memo(FormDisplay);
