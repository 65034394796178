import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Spin, Modal, Input, Form, InputNumber, Col, Popconfirm, Table, Typography } from 'antd';
import { PlusOutlined, VerticalAlignTopOutlined } from '@ant-design/icons';

import moment from 'moment';
import { useLocation } from 'react-router-dom';
import EditableTable from '../../components/EditableTable';

import './overview.css';
import DataDisplayGroup from '../../components/DataDisplay/DataDisplayGroup';
import { deleteBillingPeriodDataRequest, getPevLatestSnapshotRequest, getRetainerOverviewRequest, resetRetainerOverviewRequest, updateBillingPeriodDataRequest, updateBudgetsRequest, updateComponentsRequest, updatePlanningBudgetsRequest } from '../../core/agileProject/agileProjectActions';
import { renderInitialsFromArray, renderPeriod } from '../../components/CoreTable/CellRenderers';
import projectTypes from '../../core/utils/projectTypes';
import { CellDataTypes } from '../../components/EditableTable/CellDataType';
import DatePicker from '../../components/PickerComponent/DatePicker';
import { type } from '../../core/utils/values';

const { Text } = Typography;
const COL_WIDTH_DATE = 140;
const COL_WIDTH_RATE = 120;
const TYPE_FIXED = type.find(obj => obj.fixed !== undefined)?.fixed;

const Overview = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const projectId = location?.pathname?.split('/')[2];

    const projectData = useSelector((state) => state?.agileProject);
    const settings = projectData?.settings;
    const isFetching = projectData?.isFetching;
    const billingPeriods = projectData?.billingPeriods;
    const components = projectData?.components;
    const budgetSummaryData = projectData?.summary;
    const planningBudgetsData = projectData?.planningBudget;

    // Convert the summary object to an array of objects for the table
    const budgetSummary = Object.keys(budgetSummaryData).map((key) => ({
        key,
        category: key,
        budget_hours: budgetSummaryData[key].budget_hours,
        manual_time: budgetSummaryData[key].manual_time,
        actual_hours: budgetSummaryData[key].actual_hours,
        budget_cost: budgetSummaryData[key].budget_cost,
        actual_cost: budgetSummaryData[key].actual_cost,
    }));

    const tempComponents = components?.map((item, index) => ({ ...item, key: index }));

    const [billingPeriod, setBillingPeriod] = useState([]);
    const [component, setComponent] = useState(components);
    const [budgets, setBudgets] = useState(budgetSummary);
    const [planningBudgets, setPlanningBudgets] = useState(planningBudgetsData);
    const [updatedBillingPeriods, setUpdatedBillingPeriods] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const tableRef = useRef(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [filteredSummary, setFilteredSummary] = useState({});
    const calculateTotalHours = (selectedRows, initialSum) => selectedRows.reduce((sum, row) => ({
        hours_quoted: sum.hours_quoted + Number(row.hours_quoted),
        hours_complete: sum.hours_complete + Number(row.hours_complete),
        hours_spent: sum.hours_spent + Number(row.hours_spent),
        hours_remaining: sum.hours_remaining + Number(row.hours_remaining),
        hours_delta: sum.hours_delta + Number(row.hours_delta),
        perc_complete: sum.perc_complete + Number(row.perc_complete),
        perc_delta: sum.perc_delta + Number(row.perc_delta),
    }), initialSum);

    const roundTotalHours = (totalHours) => Object.keys(totalHours).reduce((acc, key) => ({
        ...acc,
        [key]: totalHours[key].toFixed(2),
    }), {});

    const initialSum = {
        hours_quoted: 0,
        hours_complete: 0,
        hours_spent: 0,
        hours_remaining: 0,
        hours_delta: 0,
        perc_complete: 0,
        perc_delta: 0,
    };

    const updateKey = async () => {
        const newBillingPeriods = billingPeriod.map((period) => ({
            ...period,
            dev_planned_time: period?.dev_client_planned ? period?.dev_client_planned : 0,
            pm_planned_time: period?.pm_client_planned ? period?.pm_client_planned : 0,
            static_work_remaining: period?.work_remaining ? period?.work_remaining : 0,
            static_work_planned: period?.work_planned ? period?.work_planned : 0,
        }));
        setUpdatedBillingPeriods(newBillingPeriods);
    };

    const transformData = () => {
        const newBudgets = {};

        budgets.forEach((item) => {
            const budgetKey = `${item.key}_budget`;
            const manualKey = `${item.key}_manual_time`;

            newBudgets[budgetKey] = item.budget_hours;
            newBudgets[manualKey] = item.manual_time || '0';
        });

        return { newBudgets };
    };

    const transformPlanningBudgetData = () => {
        const newPlanningBudgets = {};

        planningBudgets.forEach((item) => {
            const budgetKey = item.key;

            newPlanningBudgets[budgetKey] = item.budget_hours;
        });

        return { newPlanningBudgets };
    };

    useEffect(() => {
        resetRetainerOverviewRequest();
        if (projectId) {
            dispatch(
                getRetainerOverviewRequest({
                    projectId,
                }),
            );
            dispatch(getPevLatestSnapshotRequest({ projectId }));
        }
        if (billingPeriods) {
            const tempBillingPeriods = billingPeriods?.map((item, index) => ({ ...item, key: index }));
            setBillingPeriod(tempBillingPeriods);
        }
        if (components) {
            setComponent(tempComponents);
        }
        setBudgets(budgetSummary);
        setPlanningBudgets(planningBudgetsData);
    }, []);

    useEffect(() => {
        if (billingPeriods) {
            updateKey().then(() => {
                const tempBillingPeriods = billingPeriods?.map((item, index) => ({ ...item, key: index }));
                setBillingPeriod(tempBillingPeriods);
            });
        }
    }, [billingPeriods]);

    useEffect(() => {
        updateKey();
    }, [billingPeriod]);

    useEffect(() => {
        setBudgets(budgetSummary);
    }, [budgetSummaryData]);

    useEffect(() => {
        setPlanningBudgets(planningBudgetsData);
    }, [planningBudgetsData]);

    useEffect(() => {
        setComponent(tempComponents);
    }, [components]);

    const handleComponentUpdate = () => {
        if (component.length > 0) {
            const updatedComponents = component.filter(comp => comp.id != null).map(comp => {
                const updatedComp = { ...comp };

                if (comp.due_date) {
                    updatedComp.due_date = moment(comp.due_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
                }
                if (comp.billing_due_date) {
                    updatedComp.billing_due_date = moment(comp.billing_due_date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD');
                }
                return updatedComp;
            });
            dispatch(updateComponentsRequest(updatedComponents));
        }
    };

    const handleBudgetsUpdate = () => {
        if (budgets.length > 0) {
            const { newBudgets } = transformData();
            dispatch(updateBudgetsRequest({ projectId, budgets: newBudgets }));
        }
    };

    const handlePlanningBudgetsUpdate = () => {
        if (planningBudgets.length > 0) {
            const { newPlanningBudgets } = transformPlanningBudgetData();
            dispatch(updatePlanningBudgetsRequest({ projectId, budgets: newPlanningBudgets }));
        }
    };

    const handleDelete = (id) => {
        dispatch(deleteBillingPeriodDataRequest({ billingPeriodId: id }));
    };

    const info = [
        {
            id: '1',
            title: 'Project Info',
            items: [
                { id: '1', label: 'Client Name', content: settings?.clientName || 'Fetch Project Details or fill in Settings Sheet' },
                { id: '2', label: 'Project Name', content: settings?.projectName || 'Fetch Project Details or fill in Settings Sheet' },
                { id: '3', label: 'Project Description', content: settings?.projectDescription || 'Fetch Project Details or fill in Settings Sheet' },
                { id: '4', label: 'Project Manager', content: settings?.projectManager || 'Fetch Project Details or fill in Settings Sheet' },
                { id: '5', label: 'Lead Developer', content: settings?.leadDeveloper || 'Fetch Project Details or fill in Settings Sheet' },
                { id: '6', label: 'Project Code', content: settings?.projectCode || 'Enter on the Settings Sheet' },
            ],
        },
    ];

    const overviewColumns = [
        {
            title: 'Delete',
            dataIndex: 'delete',
            render: (_, record) => (
                billingPeriod.length >= 1 ? (
                    <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(record.id)}>
                        <Button type="text" className="button-delete">
                            Delete
                        </Button>
                    </Popconfirm>
                ) : null
            ),
            fixed: 'left',
        },
        {
            title: 'Period',
            dataIndex: 'period',
            key: 'period',
            width: 200,
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'Period cannot be empty!',
                },
            ],
            render: (data) => renderPeriod(data),
            fixed: 'left',
        },
        {
            title: 'Hourly rate',
            dataIndex: 'hourly_rate',
            key: 'hourly_rate',
            width: COL_WIDTH_RATE,
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'Hourly rate cannot be empty!',
                },
            ],
            fixed: 'left',
            cellDataType: CellDataTypes.NUMBER,
        },
        {
            title: 'Start Date',
            dataIndex: 'start_date',
            key: 'start_date',
            width: COL_WIDTH_DATE,
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'Start date is required.',
                },
            ],
            fixed: 'left',
            cellDataType: CellDataTypes.DATE_PICKER,
            format: 'date',
        },
        {
            title: 'End Date',
            dataIndex: 'end_date',
            key: 'end_date',
            width: COL_WIDTH_DATE,
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'End date is required.',
                },
            ],
            fixed: 'left',
            cellDataType: CellDataTypes.DATE_PICKER,
            format: 'date',
        },
        {
            title: 'Sprints',
            dataIndex: 'sprints',
            key: 'sprints',
            width: 140,
            editable: true,
            fixed: 'left',
        },
        {
            title: 'Σ Planned work (hrs)',
            dataIndex: 'work_planned',
            key: 'work_planned',
            width: 150,
        },
        {
            title: 'Σ Work remaining (hrs)',
            dataIndex: 'work_remaining',
            key: 'work_remaining',
            width: 150,
        },
        {
            title: 'Σ Time Spent on tickets',
            dataIndex: 'dev_time_tickets',
            key: 'dev_time_tickets',
            width: 150,
        },
        {
            title: 'Σ Dev time excl. tickets',
            dataIndex: 'dev_time_coordination',
            key: 'dev_time_coordination',
            width: 150,
        },
        {
            title: 'Dev Planned Hours',
            dataIndex: 'dev_client_planned',
            key: 'dev_client_planned',
            width: 150,
            cellDataType: CellDataTypes.NUMBER,
            editable: true,
        },
        {
            title: 'Dev Actual Hours',
            dataIndex: 'dev_time_actual',
            key: 'dev_time_actual',
            width: 150,
        },
        {
            title: 'PM Planned Hours',
            dataIndex: 'pm_client_planned',
            key: 'pm_client_planned',
            width: 150,
            cellDataType: CellDataTypes.NUMBER,
            editable: true,
        },
        {
            title: 'PM Actual Hours',
            dataIndex: 'pm_time_actual_pm',
            key: 'pm_time_actual_pm',
            width: 150,
        },
        {
            title: 'QA Actual Hours',
            dataIndex: 'pm_time_actual_qa',
            key: 'pm_time_actual_qa',
            width: 150,
        },
        {
            title: 'Σ Planned hours',
            dataIndex: 'total_planned_time',
            key: 'total_planned_time',
            width: 150,
        },
        {
            title: 'Unforseen time',
            dataIndex: 'unforeseen_time',
            key: 'unforeseen_time',
            width: 170,
        },
        {
            title: 'Non-billable',
            dataIndex: 'non_billable_time',
            key: 'non_billable_time',
            width: 170,
        },
        {
            title: 'Σ Actual time spent',
            dataIndex: 'spent_time_actual',
            key: 'spent_time_actual',
            width: 170,
        },
        {
            title: 'Hours owed/owing',
            dataIndex: 'time_owing',
            key: 'time_owing',
            width: 170,
        },
    ];

    const componentsColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 200,
            align: 'center',
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'Name cannot be empty!',
                },
            ],
            fixed: 'left',
        },
        {
            title: 'Dev Due Date',
            dataIndex: 'due_date',
            key: 'due_date',
            width: COL_WIDTH_DATE,
            align: 'center',
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'Due date is required.',
                },
            ],
            fixed: 'left',
            cellDataType: CellDataTypes.DATE_PICKER,
            format: 'date',
        },
        {
            title: 'Billing Due Date',
            dataIndex: 'billing_due_date',
            key: 'billing_due_date',
            width: COL_WIDTH_DATE,
            align: 'center',
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'Due date is required.',
                },
            ],
            fixed: 'left',
            cellDataType: CellDataTypes.DATE_PICKER,
            format: 'date',
        },
        {
            title: 'Hrs Quoted',
            dataIndex: 'hours_quoted',
            key: 'hours_quoted',
            width: 50,
            align: 'center',
        },
        {
            title: 'Hrs Complete',
            dataIndex: 'hours_complete',
            key: 'hours_complete',
            width: 50,
            align: 'center',
        },
        {
            title: 'Hrs Spent',
            dataIndex: 'hours_spent',
            key: 'hours_spent',
            width: 50,
            align: 'center',
        },
        {
            title: 'Hrs Remaining',
            dataIndex: 'hours_remaining',
            key: 'hours_remaining',
            width: 50,
            align: 'center',
        },
        {
            title: 'Hrs ∆',
            dataIndex: 'hours_delta',
            key: 'hours_delta',
            width: 50,
            align: 'center',
        },
        {
            title: '% Complete',
            dataIndex: 'perc_complete',
            key: 'perc_complete',
            width: 50,
            align: 'center',
            render: (text) => `${Number(text).toFixed(2)}%`, // just for decoration

        },
        {
            title: '% ∆',
            dataIndex: 'perc_delta',
            key: 'perc_delta',
            width: 50,
            align: 'center',
            render: (text) => `${Number(text).toFixed(2)}%`, // just for decoration

        },
        {
            title: 'Ready to Bill',
            dataIndex: 'ready_to_bill',
            key: 'ready_to_bill',
            width: 50,
            align: 'center',
        },
    ];
    const convertSnakeToTitleCase = (s) => s
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase())
        .replace(/[-_]+(.)/g, (_, c) => ` ${c.toUpperCase()}`);

    const budgetSummaryColumns = [
        {
            title: 'Name',
            dataIndex: 'category',
            key: 'category',
            width: 200,
            fixed: 'left',
            align: 'center',
            render: (text) => convertSnakeToTitleCase(text),

        },
        {
            title: 'Budget Hours',
            dataIndex: 'budget_hours',
            key: 'budget_hours',
            width: 50,
            align: 'center',
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'Budget cannot be empty!',
                },
            ],
        },
        {
            title: 'Manual +/-',
            dataIndex: 'manual_time',
            key: 'manual_time',
            width: 50,
            align: 'center',
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'Manual time cannot be empty!',
                },
            ],
        },
        {
            title: 'Actual Hours',
            dataIndex: 'actual_hours',
            key: 'actual_hours',
            width: 50,
            align: 'center',
        },
        {
            title: 'Budget Cost(£)',
            dataIndex: 'budget_cost',
            key: 'budget_cost',
            align: 'center',
            width: 50,
            render: (text) => `£${text}`,
        },
        {
            title: 'Actual Cost(£)',
            dataIndex: 'actual_cost',
            key: 'actual_cost',
            align: 'center',
            width: 50,
            render: (text) => `£${text}`,
        },
    ];

    const planningBudgetColumns = [
        {
            title: 'Activity',
            dataIndex: 'activity',
            key: 'activity',
            align: 'center',
            width: 50,
        },
        {
            title: 'Hour Budget',
            dataIndex: 'budget_hours',
            key: 'budget_hours',
            align: 'center',
            width: 50,
            editable: true,
            rules: [
                {
                    required: true,
                    message: 'Budget cannot be empty!',
                },
            ],
        },
        {
            title: 'Time Spent (hours)',
            dataIndex: 'time_spent',
            key: 'time_spent',
            align: 'center',
            width: 50,
            render: (text) => {
                const number = parseFloat(text);
                return Number.isNaN(number) ? text : number.toFixed(2);
            },
        },
        {
            title: '% of Total Time Spent',
            dataIndex: 'perc_total_time_spent',
            key: 'perc_total_time_spent',
            align: 'center',
            width: 50,
            render: (text) => `${text}%`,
        },
        {
            title: 'Names Initials',
            dataIndex: 'name_initial',
            key: 'name_initial',
            align: 'center',
            width: 50,
            render: (data) => renderInitialsFromArray(data),
        },
    ];

    const totalSummary = {
        initialColSpan: 0,
        titlePosition: 'right',
        columns: [
            { key: 'name', default: 'Overall Budget' },
            { key: 'budget_hours' },
            { key: 'manual_time' },
            { key: 'actual_hours' },
            { key: 'budget_cost', type: 'currency' },
            { key: 'actual_cost', type: 'currency' },
        ],
    };

    const totalPlanningSummary = {
        initialColSpan: 0,
        titlePosition: 'right',
        columns: [
            { key: 'activity', default: 'Total Time Spent' },
            { key: 'budget_hours' },
            { key: 'time_spent' },
            { key: 'perc_total_time_spent' },
            { key: 'name_initial', default: 'N/A' },
        ],
    };

    const summary = {
        title: 'Summary',
        titlePosition: 'right',
        initialColSpan: 16,
    };

    const handleUpdate = () => {
        const name = projectTypes.find((t) => t.value === settings?.projectType);
        updateKey().then(() => {
            const payload = {
                project_type: name?.value,
                project_code: settings?.projectCode,
                client_name: settings?.clientName,
                project_name: settings?.projectName,
                project_desc: settings?.projectDescription,
                project_colour: settings?.projectColour,
                toggl_project_id: settings?.togglProjectId,
                toggl_task_id: settings?.togglTaskId,
                start_date: settings?.startDate,
                billing_weeks: settings?.billingWeeks,
                billing_periods: [...updatedBillingPeriods],
                people: {
                    lead_dev: settings?.leadDeveloper,
                    pm: settings?.projectManager,
                },
            };

            if (name) {
                dispatch(
                    updateBillingPeriodDataRequest({
                        data: { ...payload },
                    }),
                );
            }
        });
    };

    const handleNewPeriodSubmit = (value) => {
        const { period, startDate, endDate, rate } = value;

        const newPeriod = {
            period,
            start_date: startDate.format('YYYY-MM-DD'),
            end_date: endDate.format('YYYY-MM-DD'),
            hourly_rate: rate,
            sprints: [],
            pm_planned_time: updatedBillingPeriods[updatedBillingPeriods?.length - 1]?.pm_planned_time
                ? updatedBillingPeriods[updatedBillingPeriods?.length - 1]?.pm_planned_time
                : 0,
            dev_planned_time: updatedBillingPeriods[updatedBillingPeriods?.length - 1]?.dev_planned_time
                ? updatedBillingPeriods[updatedBillingPeriods?.length - 1]?.dev_planned_time
                : 0,
        };
        const name = projectTypes.find((t) => t.value === settings?.projectType);

        updateKey().then(() => {
            const payload = {
                project_type: name?.value,
                project_code: settings?.projectCode,
                client_name: settings?.clientName,
                project_name: settings?.projectName,
                project_desc: settings?.projectDescription,
                project_colour: settings?.projectColour,
                toggl_project_id: settings?.togglProjectId,
                toggl_task_id: settings?.togglTaskId,
                start_date: settings?.startDate,
                billing_weeks: settings?.billingWeeks,
                billing_periods: [...updatedBillingPeriods, { ...newPeriod }],
                people: {
                    lead_dev: settings.leadDeveloper,
                    pm: settings.projectManager,
                },
            };

            if (name) {
                dispatch(
                    updateBillingPeriodDataRequest({
                        data: { ...payload },
                    }),
                );

                setIsModalOpen(false);
                form.resetFields();
            }
        });
    };

    const tableHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Sprint Summary</h3>
            <div>
                <Button
                    disabled={isFetching}
                    type="primary"
                    size="medium"
                    style={{ backgroundColor: '#4AC245', borderColor: '#4AC245' }}
                    icon={<PlusOutlined />}
                    onClick={() => setIsModalOpen(true)}>
                    Add New Billing Period Name
                    {isFetching && <Spin style={{ marginLeft: 10 }} size="small" />}
                </Button>
                <Button
                    disabled={isFetching}
                    type="primary"
                    size="medium"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={handleUpdate}>
                    Update Data
                    {isFetching && <Spin style={{ marginLeft: 10 }} size="small" />}
                </Button>
            </div>
        </Row>
    );

    const componentsTableHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Components</h3>
            <div>
                <Button
                    disabled={isFetching}
                    type="primary"
                    size="medium"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={handleComponentUpdate}>
                    Update Component Data
                    {isFetching && <Spin style={{ marginLeft: 10 }} size="small" />}
                </Button>
            </div>
        </Row>
    );

    const budgetSummaryTableHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Project budgets/actuals</h3>
            <div>
                <Button
                    disabled={isFetching}
                    type="primary"
                    size="medium"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={handleBudgetsUpdate}>
                    Update Budget Data
                    {isFetching && <Spin style={{ marginLeft: 10 }} size="small" />}
                </Button>
            </div>
        </Row>
    );

    const planningBudgetTableHeader = () => (
        <Row style={{ justifyContent: 'space-between', marginInline: 15 }} align="middle">
            <h3>Planning budgets/actuals</h3>
            <div>
                <Button
                    disabled={isFetching}
                    type="primary"
                    size="medium"
                    style={{ marginLeft: 10, marginRight: 10 }}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={handlePlanningBudgetsUpdate}>
                    Update Planning Budget Data
                    {isFetching && <Spin style={{ marginLeft: 10 }} size="small" />}
                </Button>
            </div>
        </Row>
    );

    const renderBillingPeriodTable = () => (
        <div style={{ flex: 0.5, width: '100%' }}>
            <EditableTable
                tableRef={tableRef}
                title={tableHeader}
                columns={overviewColumns}
                dataSource={billingPeriod}
                setDataSource={(data) => setBillingPeriod(data)}
                loading={isFetching}
                summary={summary} />
        </div>
    );
    const rowsCount = selectedRowKeys.length;

    function getDisplayValue(key, tempSummary) {
        if (tempSummary[key] === undefined) return 0;
        if (key.includes('perc')) {
            const avg = rowsCount > 0 ? (tempSummary[key] / rowsCount).toFixed(2) : 0;
            return `${avg}%`;
        }
        return tempSummary[key];
    }

    const SummaryRow = () => (
        <Table.Summary fixed>
            <Table.Summary.Row>
                <Table.Summary.Cell index={0} colSpan={2} align="center">
                    <Text strong>Summary</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center">
                    <Text strong>N/A</Text>
                </Table.Summary.Cell>
                <Table.Summary.Cell align="center">
                    <Text strong>N/A</Text>
                </Table.Summary.Cell>
                {['hours_quoted', 'hours_complete', 'hours_spent', 'hours_remaining', 'hours_delta', 'perc_complete', 'perc_delta'].map((key, index) => (
                    <Table.Summary.Cell index={index + 2} key={key} align="center">
                        <Text strong>
                            {getDisplayValue(key, filteredSummary)}
                        </Text>
                    </Table.Summary.Cell>
                ))}
                <Table.Summary.Cell align="center">
                    <Text strong>N/A</Text>
                </Table.Summary.Cell>
            </Table.Summary.Row>
        </Table.Summary>
    );

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    useEffect(() => {
        if (component && component.length > 0) {
            const allKeys = component.map((row) => row.key);
            setSelectedRowKeys(allKeys);
        }
    }, [component]);

    useEffect(() => {
        const updateComponentSummary = () => {
            const selectedRows = component.filter((row) => selectedRowKeys.includes(row.key));
            const totalHours = calculateTotalHours(selectedRows, initialSum);
            const totalHoursRounded = roundTotalHours(totalHours);
            setFilteredSummary(totalHoursRounded);
        };

        updateComponentSummary();
    }, [selectedRowKeys, component]);

    const renderComponentsTable = () => (
        <div style={{ flex: 1, width: '100%', margin: 5 }}>
            <EditableTable
                rowSelection={rowSelection}
                title={componentsTableHeader}
                columns={componentsColumns}
                dataSource={component}
                pagination={false}
                setDataSource={(data) => setComponent(data)}
                loading={isFetching}
                bordered
                summaryObject={() => <SummaryRow filteredSummary={filteredSummary} />} />
        </div>
    );

    const renderBudgetSummaryTable = () => (
        <div style={{ flex: 1, width: '100%', margin: 5 }}>
            <EditableTable
                title={budgetSummaryTableHeader}
                columns={budgetSummaryColumns}
                dataSource={budgets}
                setDataSource={(data) => setBudgets(data)}
                loading={isFetching}
                totalShown={false}
                summary={totalSummary} />
        </div>
    );

    const renderPlanningBudgetSummaryTable = () => (
        <div style={{ flex: 1, width: '100%', margin: 5 }}>
            <EditableTable
                title={planningBudgetTableHeader}
                columns={planningBudgetColumns}
                dataSource={planningBudgets}
                setDataSource={(data) => setPlanningBudgets(data)}
                loading={isFetching}
                totalShown={false}
                summary={totalPlanningSummary} />
        </div>
    );

    return (
        <div style={{ margin: 50, marginTop: 20 }}>
            <div style={{ maxWidth: 700, justifyContent: 'center' }}>
                {isFetching && <Spin style={{ position: 'absolute', top: 240, left: 500 }} />}
                {info.map((item) => (
                    <DataDisplayGroup key={item.id} title={item.title} subtitle={item.subtitle} items={item.items} />
                ))}
            </div>
            <div>
                {!isFetching && projectData?.settings?.projectType === TYPE_FIXED && renderComponentsTable()}
                {!isFetching && projectData?.settings?.projectType === TYPE_FIXED && renderBudgetSummaryTable()}
                {!isFetching && projectData?.settings?.projectType === TYPE_FIXED && renderPlanningBudgetSummaryTable()}
            </div>
            <div>{!isFetching && projectData?.settings?.projectType !== TYPE_FIXED && renderBillingPeriodTable()}</div>
            <div>
                <Modal
                    title="Create a New Billing Period"
                    open={isModalOpen}
                    width={700}
                    onOk={async () => {
                        try {
                            await form.validateFields();
                            form.submit();
                        } catch (e) {
                            // Do nothing, antd handles this itself.
                        }
                    }}
                    onCancel={() => setIsModalOpen(false)}
                    okButtonProps={{ style: { backgroundColor: '#4AC245', borderColor: '#4AC245' } }}
                    okText="Create">
                    <Form
                        name="new-sprint-form"
                        form={form}
                        layout="vertical"
                        initialValues={{
                            rate: billingPeriod[billingPeriod?.length - 1]?.hourly_rate
                                ? billingPeriod[billingPeriod?.length - 1]?.hourly_rate
                                : 0,
                        }}
                        onFinish={(data) => handleNewPeriodSubmit(data)}>
                        <Form.Item label="Billing Period" rules={[{ required: true, message: 'This cannot be left empty!' }]} name="period">
                            <Input placeholder="Enter a period" name="period" />
                        </Form.Item>
                        <Row gutter={20}>
                            <Col>
                                <Form.Item label="Hourly rate" rules={[{ required: true, message: 'This cannot be left empty!' }]} name="rate">
                                    <InputNumber placeholder={0} style={{ borderRadius: 7, padding: 6 }} name="rate" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label="Start date"
                                    rules={[{ required: true, message: 'Ensure the format is correct!' }]}
                                    name="startDate">
                                    <DatePicker
                                        placeholder={moment().format('DD/MM/YYYY')}
                                        format="DD/MM/YYYY"
                                        style={{ borderRadius: 7, padding: 10 }}
                                        name="startDate" />
                                </Form.Item>
                            </Col>
                            <Col>
                                <Form.Item
                                    label="End date"
                                    rules={[
                                        { required: true, message: 'Ensure the format is correct!' },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('startDate').isBefore(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('End date must be later than start date'));
                                            },
                                        }),
                                    ]}
                                    name="endDate">
                                    <DatePicker
                                        placeholder={moment().add(1, 'M').format('DD/MM/YYYY')}
                                        format="DD/MM/YYYY"
                                        style={{ borderRadius: 7, padding: 10 }}
                                        name="endDate" />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default Overview;
