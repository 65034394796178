import { createActions } from 'redux-actions';
import keyMirror from 'key-mirror';

export const constants = keyMirror({
    GET_PROJECTS_REQUEST: null,
    GET_PROJECTS_SUCCESS: null,
    GET_PROJECTS_FAILURE: null,

    UPDATE_REPORTED_STATUS_REQUEST: null,
    UPDATE_REPORTED_STATUS_SUCCESS: null,
    UPDATE_REPORTED_STATUS_FAILURE: null,
});

export const {
    getProjectsRequest,
    getProjectsSuccess,
    getProjectsFailure,
} = createActions(
    constants.GET_PROJECTS_REQUEST,
    constants.GET_PROJECTS_SUCCESS,
    constants.GET_PROJECTS_FAILURE,
);

export const {
    updateReportedStatusRequest,
    updateReportedStatusSuccess,
    updateReportedStatusFailure,
} = createActions(
    constants.UPDATE_REPORTED_STATUS_REQUEST,
    constants.UPDATE_REPORTED_STATUS_SUCCESS,
    constants.UPDATE_REPORTED_STATUS_FAILURE,
);
