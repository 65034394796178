import React, { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin, Form, Input } from 'antd';
import { LeftOutlined, PlusOutlined, VerticalAlignTopOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { isEmpty } from 'underscore';
import FormDisplay from '../../components/FormDisplay';

import './projectSettings.css';
import projectTypes from '../../core/utils/projectTypes';
import periodTypes from '../../core/utils/periodTypes';
import { getPevLatestSnapshotRequest, getRetainerOverviewRequest, resetRetainerOverviewRequest, updateProjectRequest } from '../../core/agileProject/agileProjectActions';
import Main from '../../components/layout/Main';
import { setCurrentProject } from '../../core/projectSettings/projectSettingsActions';
import { type } from '../../core/utils/values';

const activeOptions = [
    {
        label: 'Active',
        value: 10,
        active: true,
    },
    {
        label: 'Inactive',
        value: 0,
        active: false,
    },
];

const visibleToDevOptions = [
    {
        label: 'Visible',
        value: 1,
        active: true,
    },
    {
        label: 'Invisible',
        value: 0,
        active: false,
    },
];

const ProjectSettings = ({ project, isExistingProject }) => {
    const { settings, isFetching, billingPeriods } = useSelector((state) => state.agileProject || {});
    const { users } = useSelector((state) => state?.user || {});
    const dispatch = useDispatch();
    const [changedSettings, setChangedSettings] = useState();
    const [setting, setSetting] = useState(settings);
    const [updatedBillingPeriods, setUpdatedBillingPeriods] = useState(null);
    const [projectManager, setProjectManager] = useState([]);
    const [projectLead, setProjectLead] = useState([]);
    const [repositories, setRepositories] = useState([]);
    const location = useLocation();
    const { pathname } = location;

    const TYPE_SPRINT = type.find(obj => obj.sprint !== undefined)?.sprint;
    const TYPE_RETAINER = type.find(obj => obj.retainer !== undefined)?.retainer;
    useEffect(() => {
        if (project) {
            dispatch(setCurrentProject(project));
            setChangedSettings(settings);
        }
    }, [project]);

    const [form] = Form.useForm();
    const updateKey = async () => {
        const newBillingPeriods = billingPeriods.map(period => ({
            ...period,
            dev_planned_time: period?.dev_client_planned ? period?.dev_client_planned : 0,
            pm_planned_time: period?.pm_client_planned ? period?.pm_client_planned : 0,
            static_work_remaining: period?.work_remaining ? period?.work_remaining : 0,
            static_work_planned: period?.work_planned ? period?.work_planned : 0,
        }));
        setUpdatedBillingPeriods(newBillingPeriods);
    };

    useEffect(() => {
        if (!isEmpty(users)) {
            setProjectManager(users.project_team);
            setProjectLead(users.lead_developer);
        }
    }, [users]);
    const handleInputChange = (id, newValue) => {
        setRepositories(prevRepositories => prevRepositories.map(repo => (repo.id === id ? { ...repo, repo_name: newValue } : repo)));
    };
    const addRepository = () => {
        const newRepo = { id: Date.now(), repo_name: '' }; // create a new repo with a unique ID
        setRepositories([...repositories, newRepo]);
    };
    const removeRepository = (id) => {
        setRepositories(repositories.filter(repo => repo.id !== id));
    };
    // Define the rendered dynamic repositories form inputs
    const renderRepositories = () => (repositories || []).map(repo => (
        <div key={repo.id} className="repository-item">
            <Form.Item
                label={`Repository: ${repo.repo_name}`}
                name={`repo-${repo.id}`}
                style={{ display: 'inline-block', width: '90%' }}
                initialValue={repo.repo_name}
                rules={[
                    {
                        required: true,
                        message: 'Please fill this in!',
                    },
                ]}>
                <Input value={repo.repo_name} onChange={(e) => handleInputChange(repo.id, e.target.value)} />
            </Form.Item>
            <MinusCircleOutlined onClick={() => removeRepository(repo.id)} style={{ fontSize: '20px', marginLeft: '10px', marginTop: '45px' }} />
        </div>
    ));
    const settingsConfig = useMemo(() => {
        const baseConfig = {
            sections: [
                {
                    id: 1,
                    title: 'Project ID',
                    name: 'projectId',
                    items: [
                        {
                            id: 1,
                            type: 'Input',
                            label: 'Project Code',
                            name: 'projectCode',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please fill this in!',
                                },
                            ],
                        },
                        {
                            id: 2,
                            type: 'Input',
                            label: 'Project Version',
                            name: 'projectName',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please fill this in!',
                                },
                            ],
                            config: {
                                disabled: pathname !== '/report/create' && changedSettings?.projectName,
                            },
                        },

                    ],
                },
                {
                    id: 2,
                    title: 'Project Details',
                    items: [
                        {
                            id: 1,
                            type: 'Input',
                            label: 'Client Name',
                            name: 'clientName',
                            rules: [
                                {
                                    required: true,
                                    message: 'Please fill this in!',
                                },
                            ],
                        },
                        {
                            id: 2,
                            type: 'Input',
                            label: 'Project Rate',
                            name: 'projectRate',
                        },
                        {
                            id: 3,
                            type: 'Input.TextArea',
                            label: 'Project Description',
                            name: 'projectDescription',
                        },
                        {
                            id: 4,
                            type: 'ColorPicker',
                            label: 'Project Colour',
                            name: 'projectColour',
                            config: {
                                format: 'hex',
                            },
                        },
                        {
                            id: 5,
                            type: 'InputNumber',
                            label: 'Toggl Project Id',
                            name: 'togglProjectId',
                        },
                        {
                            id: 6,
                            type: 'InputNumber',
                            label: 'Toggl Planning Day Activities Task Id',
                            name: 'togglPlanningDayActivitiesTaskId',
                        },
                        {
                            id: 7,
                            type: 'Select',
                            label: 'Project Type',
                            name: 'projectType',
                            config: {
                                options: [
                                    ...projectTypes.map(p => ({ label: p.name, value: p.value })),
                                ],
                            },
                            rules: [
                                {
                                    required: true,
                                    message: 'Please fill this in!',
                                },
                            ],
                        },
                        {
                            id: 8,
                            type: 'Switch',
                            label: 'Active / Inactive',
                            name: 'status',
                            config: {
                                defaultChecked: true,
                            },
                        },
                        {
                            id: 9,
                            type: 'Switch',
                            label: 'Visible to Developers',
                            name: 'visibleToDevs',
                            config: {
                                defaultChecked: true,
                            },
                        },
                    ],
                },
                {
                    id: 3,
                    title: 'Initial Budget Estimates',
                    items: [

                        {
                            id: 2,
                            type: 'Input',
                            label: 'Planning Budget',
                            name: 'planningBudget',
                        },
                        {
                            id: 3,
                            type: 'Input',
                            label: 'Graphics Budget',
                            name: 'graphicsBudget',
                        },
                        {
                            id: 4,
                            type: 'Input',
                            label: 'Development Budget',
                            name: 'devBudget',
                        },
                        {
                            id: 5,
                            type: 'Input',
                            label: 'PM Testing Budget',
                            name: 'pmTestingBudget',
                        },
                        {
                            id: 6,
                            type: 'Input',
                            label: 'Dev Fixing Budget',
                            name: 'devFixingBudget',
                        },
                        {
                            id: 7,
                            type: 'Input',
                            label: 'PM Budget',
                            name: 'pmBudget',
                        },
                        {
                            id: 8,
                            type: 'Input',
                            label: 'Dev PM Budget',
                            name: 'devPmBudget',
                        },
                    ],
                },
                {
                    id: 5,
                    title: 'Project Dates',
                    items: [
                        {
                            id: 1,
                            type: 'DatePicker',
                            label: 'Start Date',
                            name: 'startDate',
                            config: {
                                format: 'DD-MM-YYYY',
                            },
                            rules: [
                                {
                                    required: changedSettings?.projectType === TYPE_SPRINT || changedSettings?.projectType === TYPE_RETAINER,
                                    message: 'Please fill this in!',
                                },
                            ],
                        },
                        {
                            id: 2,
                            type: 'Select',
                            label: 'Billing Period',
                            name: 'billingPeriod',
                            rules: [
                                {
                                    required: changedSettings?.projectType === TYPE_SPRINT || changedSettings?.projectType === TYPE_RETAINER,
                                    message: 'Please fill this in!',
                                },
                            ],
                            config: {
                                options: [
                                    ...periodTypes.map(p => ({ label: p.name, value: p.value })),
                                ],
                            },
                        },
                    ],
                },
                {
                    id: 6,
                    title: 'Key People',
                    items: [
                        {
                            id: 1,
                            type: 'Select',
                            label: 'Project Manager',
                            name: 'projectManager',
                            config: {
                                options: [
                                    ...projectManager.map(p => ({ label: `${p.first_name} ${p.last_name}`, value: `${p.first_name} ${p.last_name}` })),
                                ],
                            },
                        },
                        {
                            id: 2,
                            type: 'Select',
                            label: 'Lead Developer',
                            name: 'leadDeveloper',
                            config: {
                                options: [
                                    ...projectLead.map(p => ({ label: `${p.first_name} ${p.last_name}`, value: `${p.first_name} ${p.last_name}` })),
                                ],
                            },
                        },
                    ],
                },
                {
                    id: 7,
                    title: 'Repositories',
                    customRender: (
                        <>
                            {renderRepositories()}
                            <Form.Item style={{ textAlign: 'center' }}>
                                <Button type="primary" style={{ width: '100%' }} onClick={addRepository} icon={<PlusCircleOutlined />}>
                                    Add Repository
                                </Button>
                            </Form.Item>
                        </>
                    ),
                },
            ],
        };
        if (changedSettings?.projectType === TYPE_SPRINT) {
            baseConfig.sections.push({
                id: 4,
                title: 'Initial Project Estimates',
                items: [
                    {
                        id: 1,
                        type: 'Input',
                        label: 'PM Most Likely Time (hours)',
                        name: 'pmMostLikely',
                    },
                    {
                        id: 3,
                        type: 'Input',
                        label: 'PM Pessimistic Time (hours)',
                        name: 'pmPessimistic',
                    },
                    {
                        id: 4,
                        type: 'Input',
                        label: 'PM Optimistic Time (hours)',
                        name: 'pmOptimistic',
                    },
                    {
                        id: 5,
                        type: 'Input',
                        label: 'Dev PM Most Likely Time (hours)',
                        name: 'devPmMostLikely',
                    },
                    {
                        id: 6,
                        type: 'Input',
                        label: 'Dev PM Pessimistic Time (hours)',
                        name: 'devPmPessimistic',
                    },
                    {
                        id: 7,
                        type: 'Input',
                        label: 'Dev PM Optimistic Time (hours)',
                        name: 'devPmOptimistic',
                    },
                    {
                        id: 8,
                        type: 'Input',
                        label: 'Test Most Likely Time (hours)',
                        name: 'testMostLikely',
                    },
                    {
                        id: 9,
                        type: 'Input',
                        label: 'Test Pessimistic Time (hours)',
                        name: 'testPessimistic',
                    },
                    {
                        id: 10,
                        type: 'Input',
                        label: 'Test Optimistic Time (hours)',
                        name: 'testOptimistic',
                    },
                ],

            });
        }
        return baseConfig;
    }, [changedSettings, projectManager, projectLead, pathname, repositories]);

    const handleFieldChange = (changed) => {
        const { name, value } = changed[0];
        const dataIndex = name[0];
        const entry = {};
        entry[dataIndex] = value;
        setChangedSettings({ ...changedSettings, ...entry });
    };

    const formConfig = {
        initialValues: {
            projectCode: changedSettings?.projectCode,
            projectName: changedSettings?.projectName,
            clientName: changedSettings?.clientName,
            projectDescription: changedSettings?.projectDescription,
            projectColour: changedSettings?.projectColour ? setting?.projectColour : '#000000',
            togglProjectId: changedSettings?.togglProjectId,
            togglPlanningDayActivitiesTaskId: changedSettings?.togglPlanningDayActivitiesTaskId,
            projectType: changedSettings?.projectType,
            startDate: changedSettings?.startDate ? moment(setting?.startDate, 'YYYY-MM-DD') : moment(),
            billingPeriod: changedSettings?.billingPeriod ?? 4,
            projectManager: changedSettings?.projectManager,
            leadDeveloper: changedSettings?.leadDeveloper,
            status: changedSettings?.status !== 0,
            projectRate: changedSettings?.projectRate ?? 0,
            planningBudget: changedSettings?.planningBudget ?? 0,
            graphicsBudget: changedSettings?.graphicsBudget ?? 0,
            devBudget: changedSettings?.devBudget ?? 0,
            pmTestingBudget: changedSettings?.pmTestingBudget ?? 0,
            devFixingBudget: changedSettings?.devFixingBudget ?? 0,
            pmBudget: changedSettings?.pmBudget ?? 0,
            devPmBudget: changedSettings?.devPmBudget ?? 0,
            pmMostLikely: changedSettings?.pmMostLikely ?? 0,
            pmPessimistic: changedSettings?.pmPessimistic ?? 0,
            pmOptimistic: changedSettings?.pmOptimistic ?? 0,
            devPmMostLikely: changedSettings?.devPmMostLikely ?? 0,
            devPmPessimistic: changedSettings?.devPmPessimistic ?? 0,
            devPmOptimistic: changedSettings?.devPmOptimistic ?? 0,
            testMostLikely: changedSettings?.testMostLikely ?? 0,
            testPessimistic: changedSettings?.testPessimistic ?? 0,
            testOptimistic: changedSettings?.testOptimistic ?? 0,
            visibleToDevs: changedSettings?.visibleToDevs ?? 1,
            ...(changedSettings?.repos ? repositories.reduce((acc, repo) => ({ ...acc, [`repo-${repo?.id}`]: repo?.repo_name || '' }), {}) : {}),
        },
        layout: 'vertical',
        labelAlign: 'left',
        onFieldsChange: (changed) => handleFieldChange(changed),
    };

    const handleButtonPress = async () => {
        try {
            await form.validateFields();

            const name = projectTypes.find(t => t.value === changedSettings?.projectType);
            const active = activeOptions.find(t => t.active === changedSettings?.status);
            const visibleToDev = visibleToDevOptions.find(t => t.active === changedSettings?.visibleToDevs);
            if (name) {
                updateKey().then(() => {
                    dispatch(updateProjectRequest({
                        data: {
                            id: changedSettings.id,
                            project_type: name?.value,
                            project_code: changedSettings.projectCode,
                            project_name: changedSettings.projectName,
                            client_name: changedSettings.clientName,
                            billing_weeks: changedSettings.billingPeriod ? parseInt(changedSettings.billingPeriod, 10) : '4',
                            project_description: changedSettings.projectDescription,
                            project_colour: typeof changedSettings?.projectColour === 'string'
                                ? changedSettings.projectColour
                                : (changedSettings?.projectColour?.toHexString?.()?.slice(1) || '000000'),
                            toggl_project_id: parseInt(changedSettings.togglProjectId, 10),
                            toggl_task_id: parseInt(changedSettings.togglPlanningDayActivitiesTaskId, 10),
                            project_rate: parseFloat(changedSettings.projectRate),
                            start_date: changedSettings.startDate ? moment(changedSettings.startDate).utc().format('YYYY-MM-DD') : moment().utc().format('YYYY-MM-DD'),
                            people: {
                                lead_dev: changedSettings.leadDeveloper,
                                pm: changedSettings.projectManager,
                            },
                            status: active?.value,
                            visible_to_devs: visibleToDev?.value,
                            planning_budget: changedSettings.planningBudget,
                            graphics_budget: changedSettings.graphicsBudget,
                            dev_budget: changedSettings.devBudget,
                            pm_testing_budget: changedSettings.pmTestingBudget,
                            dev_fixing_budget: changedSettings.devFixingBudget,
                            pm_budget: changedSettings.pmBudget,
                            dev_pm_budget: changedSettings.devPmBudget,
                            billing_periods: updatedBillingPeriods,
                            estimates: {
                                pm_most_likely: parseInt(changedSettings.pmMostLikely, 10),
                                pm_pessimistic: parseInt(changedSettings.pmPessimistic, 10),
                                pm_optimistic: parseInt(changedSettings.pmOptimistic, 10),
                                dev_pm_most_likely: parseInt(changedSettings.devPmMostLikely, 10),
                                dev_pm_pessimistic: parseInt(changedSettings.devPmPessimistic, 10),
                                dev_pm_optimistic: parseInt(changedSettings.devPmOptimistic, 10),
                                test_most_likely: parseInt(changedSettings.testMostLikely, 10),
                                test_pessimistic: parseInt(changedSettings.testPessimistic, 10),
                                test_optimistic: parseInt(changedSettings.testOptimistic, 10),
                            },
                            repositories: repositories.map(repo => ({ id: repo.id, repo_name: repo.repo_name })),
                        },
                    }));
                });
            }
        } catch (error) {
            console.log({ error });
        }
    };
    useEffect(() => {
        setSetting(settings);
        setChangedSettings(settings);
        if (!project) {
            setChangedSettings(null);
        }
        setRepositories(Array.isArray(settings.repos) ? settings.repos : []);
    }, [settings]);

    useEffect(() => {
        if (settings?.projectCode && settings.projectName && !settings) {
            dispatch(getRetainerOverviewRequest({
                projectCode: settings?.projectCode,
                projectName: settings?.projectName,
            }));
            dispatch(getPevLatestSnapshotRequest({ projectId: settings?.id }));
        }
        if (pathname === '/report/create') {
            dispatch(resetRetainerOverviewRequest());
        }
    }, []);
    const renderForm = useMemo(() => {
        if (changedSettings && !isFetching || !project && projectManager) {
            return (
                <FormDisplay form={form} config={settingsConfig} formConfig={formConfig}>
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button disabled={isFetching || !settings} type="primary" size="large" style={{ width: 300, marginRight: 30 }} icon={settings?.projectCode ? <VerticalAlignTopOutlined /> : <PlusOutlined />} onClick={() => handleButtonPress()}>
                            {settings?.projectCode ? 'Update Project Settings' : 'Create New Project'}
                            {isFetching || !settings && (<Spin style={{ marginLeft: 10 }} size="small" />)}
                        </Button>
                    </Form.Item>
                </FormDisplay>
            );
        }

        return (<Spin style={{ marginTop: 50, marginBottom: 20 }} size="small" />);
    }, [changedSettings, isFetching, projectManager, settingsConfig, form]);

    return (
        <div>
            {!isExistingProject ? (
                <Main floatingHeader={false} showHeader title="Create a Project">
                    <Link to="/dashboard">
                        <LeftOutlined /> Dashboard
                    </Link>
                </Main>
            ) : null}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                { renderForm }
            </div>
        </div>
    );
};

ProjectSettings.propTypes = {
    project: PropTypes.string,
    isExistingProject: PropTypes.bool,
};

ProjectSettings.defaultProps = {
    project: null,
    isExistingProject: false,
};

export default ProjectSettings;
